import ArtDirectedGatsbyImage from '@root/inc.joinroot.com/src/components/art-directed-gatsby-image';
import MainContent from '@root/inc.joinroot.com/src/components/main-content';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { BACKGROUND_COLOR } from '@root/inc.joinroot.com/src/components/page-sections/support/background-colors';
import { Colors, styled } from '@root/core/src/utils/styles';
import { IMAGE_SIZE, SECTION_LAYOUT_X, SECTION_LAYOUT_Y } from '@root/inc.joinroot.com/src/components/page-sections/side-by-side-with-image/section-options';

export default function SideBySideWithImageSection({
  anchor,
  mainContent,
  backgroundColor = BACKGROUND_COLOR['White'],
  sectionLayoutX = SECTION_LAYOUT_X['Image left'],
  sectionLayoutY = SECTION_LAYOUT_Y['Align center'],
  imageSize = IMAGE_SIZE['Large'],
  image: {
    desktopImg,
    imageAltText,
    mobileImg,
  },
  sectionSpacing = {},
}) {
  return (
    <Section
      backgroundColor={backgroundColor}
      id={anchor}
      sectionSpacing={sectionSpacing}
    >
      <Wrap
        sectionLayoutX={sectionLayoutX}
        sectionLayoutY={sectionLayoutY}
      >
        <Image
          alt={imageAltText}
          desktopImg={desktopImg}
          imgStyle={{ objectFit: 'contain' }}
          mobileImg={mobileImg}
        />
        <ContentContainer
          imageSize={imageSize}
          sectionLayoutX={sectionLayoutX}
        >
          <MainContent {...mainContent} />
        </ContentContainer>
      </Wrap>
    </Section>
  );
}

SideBySideWithImageSection.propTypes = {
  anchor: PropTypes.string,
  backgroundColor: PropTypes.oneOf(Object.values(BACKGROUND_COLOR)),
  image: PropTypes.shape({
    imageAltText: PropTypes.string.isRequired,
    desktopImg: PropTypes.object.isRequired,
    mobileImg: PropTypes.object,
  }).isRequired,
  imageSize: PropTypes.oneOf([IMAGE_SIZE['Small'], IMAGE_SIZE['Large']]),
  mainContent: PropTypes.object.isRequired,
  sectionLayoutX: PropTypes.oneOf(Object.values(SECTION_LAYOUT_X)),
  sectionLayoutY: PropTypes.oneOf(Object.values(SECTION_LAYOUT_Y)),
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
};

const Section = styled.section({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}, ({ backgroundColor, sectionSpacing }) => {
  const sectionSpacingStyles = {
    ...Responsive.lessThanMd({
      paddingTop: sectionSpacing.top / 1.25,
      paddingBottom: sectionSpacing.bottom / 1.25,
    }),
    paddingTop: sectionSpacing.top,
    paddingBottom: sectionSpacing.bottom,
  };

  const backgroundColorStyles = {
    WHITE: { backgroundColor: Colors.white() },
    OFF_WHITE: { backgroundColor: Colors.nearWhite() },
  };

  return {
    ...sectionSpacingStyles,
    ...backgroundColorStyles[backgroundColor],
  };
});

const Wrap = styled.div({
  ...Responsive.lessThanMd({
    flexDirection: 'column',
  }),
  display: 'flex',
  width: 'min(1200px, 100%)',
  margin: '0 8%',
}, ({ sectionLayoutX, sectionLayoutY }) => {
  const sectionLayoutXStyles = sectionLayoutX === 'IMAGE_RIGHT'
    ? { flexDirection: 'row-reverse' }
    : { flexDirection: 'row' };

  const sectionLayoutYStyles = sectionLayoutY === 'ALIGN_TOP'
    ? { alignItems: 'flex-start' }
    : { alignItems: 'center' };

  return {
    ...Responsive.md({
      ...sectionLayoutXStyles,
      ...sectionLayoutYStyles,
    }),
  };
});

const Image = styled(ArtDirectedGatsbyImage)({
  ...Responsive.lessThanMd({
    margin: '0 calc(50% - 50vw)',
  }),
  flex: 1,
});

const ContentContainer = styled.div({
  ...Responsive.lessThanMd({
    width: '100%',
    padding: '30px 0 0',
    alignItems: 'center',
    textAlign: 'center',
  }),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: 0,
  textAlign: 'left',
  '& > * + *': { marginTop: 14 },
  '& > a': {
    maxWidth: 240,
    marginTop: 35,
  },
}, ({ sectionLayoutX, imageSize }) => {
  const sectionLayoutXStyles = {
    IMAGE_LEFT: { paddingLeft: '12%' },
    IMAGE_RIGHT: { paddingRight: '12%' },
  };

  const imageSizeStyles = {
    SMALL: { width: '60%' },
    LARGE: { width: 'max(42%, 340px)' },
  };

  return {
    ...sectionLayoutXStyles[sectionLayoutX],
    ...imageSizeStyles[imageSize],
  };
});
