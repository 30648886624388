import Expandable from '@root/inc.joinroot.com/src/components/page-sections/expandable/expandable';
import ExpandableSection from '@root/inc.joinroot.com/src/components/page-sections/expandable/expandable-section';
import PropTypes from '@root/vendor/prop-types';
import renderRichText from '@root/inc.joinroot.com/src/components/page-sections/support/render-rich-text';
import { BACKGROUND_COLOR } from '@root/inc.joinroot.com/src/components/page-sections/support/background-colors';

export default function ExpandableSectionBlock({
  content: {
    backgroundColor,
    expandables,
    sectionSpacing,
    anchor,
  },
}) {
  return (
    <ExpandableSection
      anchor={anchor?.anchorSlug}
      backgroundColor={BACKGROUND_COLOR[backgroundColor]}
      sectionSpacing={sectionSpacing}
    >
      {expandables.map((expandable, i) => (
        <Expandable
          anchor={expandable.anchor?.anchorSlug}
          key={i}
          title={expandable.title}
        >
          {renderRichText(expandable.textContent)}
        </Expandable>
      ))}
    </ExpandableSection>
  );
}

ExpandableSectionBlock.propTypes = {
  content: PropTypes.shape({
    anchor: PropTypes.shape({
      anchorSlug: PropTypes.string,
    }),
    backgroundColor: PropTypes.oneOf(Object.keys(BACKGROUND_COLOR)),
    sectionSpacing: PropTypes.shape({
      bottom: PropTypes.number,
      top: PropTypes.number,
    }),
    expandables: PropTypes.arrayOf(
      PropTypes.shape({
        anchor: PropTypes.shape({
          anchorSlug: PropTypes.string,
        }),
        title: PropTypes.string.isRequired,
        textContent: PropTypes.shape({
          json: PropTypes.object.isRequired,
        }),
      }).isRequired
    ).isRequired,
  }).isRequired,
};
