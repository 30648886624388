import Button from '@root/inc.joinroot.com/src/components/buttons/button';
import Modal, { MODAL_WIDTH } from '@root/inc.joinroot.com/src/components/modal/modal';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import WrapRichText from '@root/inc.joinroot.com/src/components/text/wrap-rich-text';
import { BUTTON_VARIANT } from '@root/inc.joinroot.com/src/components/buttons/button-options';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { StyleSheet, styled } from '@root/core/src/utils/styles';

export default function ContentfulModal({
  id,
  image,
  imageAltText,
  textContent,
  button,
  isModalOpen,
  setIsModalOpen,
  modalWidth = MODAL_WIDTH['Small'],
}) {
  return (
    <Modal
      id={id}
      isShowing={isModalOpen}
      modalWidth={modalWidth}
      onClose={() => setIsModalOpen(false)}
    >
      <Container
        data-testid={'modal-content-container'}
        modalWidth={modalWidth}
      >
        {image && (
          <GatsbyImage
            alt={imageAltText}
            className={'modal-image'}
            css={styles.image}
            image={getImage(image)}
          />
        )}
        <div css={styles.textContent}>
          <WrapRichText>{textContent}</WrapRichText>
          {button && (
            <Button
              onClick={() => setIsModalOpen(false)}
              styleProps={styles.button}
              variant={button.variant}
            >
              {button.text}
            </Button>
          )}
        </div>
      </Container>
    </Modal>
  );
}

ContentfulModal.propTypes = {
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(Object.values(BUTTON_VARIANT)),
  }),
  id: PropTypes.string.isRequired,
  image: PropTypes.object,
  imageAltText: PropTypes.string,
  isModalOpen: PropTypes.bool.isRequired,
  modalWidth: PropTypes.oneOf(Object.values(MODAL_WIDTH)),
  setIsModalOpen: PropTypes.func.isRequired,
  textContent: PropTypes.arrayOf(PropTypes.node).isRequired,
};

const Container = styled.div({
  display: 'flex',
}, ({ modalWidth }) => {
  const modalWidthStyles = {
    420: {
      flexDirection: 'column',
      alignItems: 'center',
      '& .modal-image': { marginBottom: 30 },
    },
    920: {
      flexDirection: 'column',
      alignItems: 'center',
      '& .modal-image': { marginBottom: 30 },
      ...Responsive.md({
        flexDirection: 'row',
        alignItems: 'flex-start',
        '& > * + *': {
          marginLeft: 45,
        },
        '& .modal-image': { marginBottom: 0 },
      }),
    },
  };

  return modalWidthStyles[modalWidth];
});

const styles = StyleSheet.create({
  image: {
    flexShrink: 0,
    width: 280,
  },
  button: {
    display: 'flex',
    margin: '24px 0 10px',
  },
});
