import SmartLink from '@root/inc.joinroot.com/src/components/smart-link';
import { blockquoteStyles, body1Styles, body2Styles, bodyLinkStyles, heading1Styles, heading2Styles, heading3Styles, heading4Styles, heading5Styles, lIStyles, oLStyles, uLStyles } from '@root/inc.joinroot.com/src/components/text/text-styles';
import { styled } from '@root/core/src/utils/styles';

export const H1 = styled.h1(heading1Styles);
export const H2 = styled.h2(heading2Styles);
export const H3 = styled.h3(heading3Styles);
export const H4 = styled.h4(heading4Styles);
export const H5 = styled.h5(heading5Styles);
export const Eyebrow = styled(H5)();
export const P1 = styled.p(body1Styles);
export const P2 = styled.p(body2Styles);
export const Link = styled(SmartLink)(bodyLinkStyles);
export const UL = styled.ul(uLStyles);
export const OL = styled.ol(oLStyles);
export const LI = styled.li(lIStyles);
export const Blockquote = styled.blockquote(blockquoteStyles);
