import ErrorBoundary from '@root/core/src/components/error-boundary';
import PropTypes from '@root/vendor/prop-types';
import environment from '@root/core/src/utils/environment';
import snakeCase from '@root/vendor/lodash/snakeCase';
import useVisibilityAnalytics from '@root/core/src/hooks/use-visibility-analytics';

function BlockRenderer({ block, blockComponentMap }) {
  const contentId = snakeCase(block?.sys?.contentType?.sys?.id)?.toUpperCase();
  const analyticsContext = `${contentId}_${block.id}`;
  const {
    nodeRef,
    trackClick,
    trackEvent,
  } = useVisibilityAnalytics(analyticsContext);

  if (!contentId) { return null; }

  const ComponentToRender = blockComponentMap[contentId];

  if (!ComponentToRender) { throw new Error(`No block component found to render ${contentId} content type`); }

  return (
    <div
      data-testid={'block-renderer'}
      ref={nodeRef}
    >
      <ComponentToRender
        content={block}
        trackClick={trackClick}
        trackEvent={trackEvent}
      />
    </div>
  );
}

export default function BlockRendererWrapper(props) {
  const isInPreviewMode = environment.contentfulPreviewEnabled;

  return isInPreviewMode
    ? (
      <ErrorBoundary showErrorScreen={false}>
        <BlockRenderer {...props} />
      </ErrorBoundary>
    )
    : <BlockRenderer {...props} />;
}

BlockRenderer.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.object,
    sys: PropTypes.shape({
      contentType: PropTypes.shape({
        sys: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      }),
    }).isRequired,
  }).isRequired,
  blockComponentMap: PropTypes.object.isRequired,
};
