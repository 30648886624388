import BlockRenderer from '@root/inc.joinroot.com/src/components/page-templates/support/block-renderer';
import Footer from '@root/inc.joinroot.com/src/components/footer/footer';
import Nav from '@root/inc.joinroot.com/src/components/nav/nav';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Seo from '@root/inc.joinroot.com/src/components/seo';
import blockComponentMap from '@root/inc.joinroot.com/src/models/block-component-map';
import buildPathFromSlug from '@root/inc.joinroot.com/src/utils/build-path-from-slug';
import calculateSectionSpacing from '@root/inc.joinroot.com/src/components/page-templates/support/calculate-section-spacing';
import environment from '@root/core/src/utils/environment';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useSaveAttribution from '@root/attribution/src/hooks/use-save-attribution';
import useSaveTouchAttributionEffect from '@root/attribution/src/hooks/use-save-touch-attribution-effect';
import { footerLinks } from '@root/inc.joinroot.com/src/components/footer/footer-data';
import { graphql } from 'gatsby';
import { navLinks } from '@root/inc.joinroot.com/src/components/nav/nav-data';

export default function BasicPage({
  data: {
    contentfulComposePage: {
      id,
      content,
      slug,
      seo,
      title,
    },
  },
  location,
}) {
  const analyticsContext = `BASIC_PAGE_${id}`;
  const blocksWithSectionSpacingData = calculateSectionSpacing(content.blocks, 'BASIC_PAGE');

  useAnalytics(analyticsContext);
  useSaveAttribution(location);
  useSaveTouchAttributionEffect();

  return (
    <>
      <Nav navLinks={navLinks} />
      <main>
        <Seo
          canonical={`${environment.websiteBaseUrl}${buildPathFromSlug(slug)}`}
          description={seo.description}
          imageURL={seo.pageThumbnail?.file?.url}
          noIndex={seo.no_index}
          title={title}
        />
        {blocksWithSectionSpacingData && blocksWithSectionSpacingData.map((block, i) => (
          <BlockRenderer
            block={block}
            blockComponentMap={blockComponentMap}
            key={`${block.id}-${i}`}
          />
        ))}
      </main>
      <Footer footerLinks={footerLinks} />
    </>
  );
}

BasicPage.propTypes = {
  data: PropTypes.shape({
    contentfulComposePage: PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.shape({
        blocks: PropTypes.array.isRequired,
      }),
      seo: PropTypes.shape({
        description: PropTypes.string.isRequired,
        no_index: PropTypes.bool,
        keywords: PropTypes.arrayOf(PropTypes.string),
        pageThumbnail: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }).isRequired,
        }),
      }),
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export const query = graphql`
  query ($id: String!) {
    contentfulComposePage(id: {eq: $id}) {
      id
      slug
      title
      seo {
        description
        no_index
        pageThumbnail {
          file {
            url
          }
        }
      }
      content {
        ... on ContentfulBasicPage {
          blocks {
            ...ContentfulSideBySideWithImageSection_fragment
            ...ContentfulRichTextSection_fragment
            ...ContentfulFeatureImageSection_fragment
            ...ContentfulFeatureImageWithContentSection_fragment
            ...ContentfulSectionSpacer_fragment
            ...ContentfulToutSection_fragment
            ...ContentfulExpandableSection_fragment
            ...ContentfulSideBySideWithToutsSection_fragment
          }
        }
      }
    }
  }
`;
