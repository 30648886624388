import ButtonRenderer from '@root/inc.joinroot.com/src/components/buttons/button-renderer';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import WrapRichText from '@root/inc.joinroot.com/src/components/text/wrap-rich-text';
import { BACKGROUND_COLOR } from '@root/inc.joinroot.com/src/components/page-sections/support/background-colors';
import { BUTTON_VARIANT } from '@root/inc.joinroot.com/src/components/buttons/button-options';
import { CONTENT_LAYOUT_X } from '@root/inc.joinroot.com/src/components/page-sections/rich-text/section-options';
import { Colors, styled } from '@root/core/src/utils/styles';

export default function RichTextSection({
  sectionSpacing = {},
  textContent,
  anchor,
  backgroundColor = BACKGROUND_COLOR['White'],
  contentLayoutX = CONTENT_LAYOUT_X['Align left'],
  button,
}) {
  return (
    <Section
      backgroundColor={backgroundColor}
      id={anchor}
      sectionSpacing={sectionSpacing}
    >
      <Wrap contentLayoutX={contentLayoutX}>
        <RichTextContent contentLayoutX={contentLayoutX}>
          {textContent}
        </RichTextContent>
        {button && (
          <ButtonRenderer
            onClick={button.trackClick}
            to={button.link}
            variant={button.variant}
          >
            {button.text}
          </ButtonRenderer>
        )}
      </Wrap>
    </Section>
  );
}

RichTextSection.propTypes = {
  anchor: PropTypes.string,
  backgroundColor: PropTypes.oneOf(Object.values(BACKGROUND_COLOR)),
  button: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string.isRequired,
    trackClick: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(Object.values(BUTTON_VARIANT)),
  }),
  contentLayoutX: PropTypes.oneOf(Object.values(CONTENT_LAYOUT_X)),
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
  textContent: PropTypes.arrayOf(PropTypes.node),
};

const Section = styled.section({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  padding: '100px 20px',
}, ({ backgroundColor, sectionSpacing }) => {
  const sectionSpacingStyles = {
    ...Responsive.lessThanMd({
      paddingTop: sectionSpacing.top / 1.25,
      paddingBottom: sectionSpacing.bottom / 1.25,
    }),
    paddingTop: sectionSpacing.top,
    paddingBottom: sectionSpacing.bottom,
  };

  const backgroundColorStyles = {
    WHITE: { backgroundColor: Colors.white() },
    OFF_WHITE: { backgroundColor: Colors.nearWhite() },
  };

  return {
    ...sectionSpacingStyles,
    ...backgroundColorStyles[backgroundColor],
  };
});

const Wrap = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 'min(800px, 100%)',
  '& > * + *': {
    marginTop: 30,
  },
}, ({ contentLayoutX }) => {
  const contentLayoutXStyles = {
    ALIGN_LEFT: { alignItems: 'flex-start' },
    ALIGN_CENTER: { alignItems: 'center' },
  };

  return contentLayoutXStyles[contentLayoutX];
});

const RichTextContent = styled(WrapRichText)(({ contentLayoutX }) => {
  const contentLayoutXStyles = {
    ALIGN_LEFT: { textAlign: 'left' },
    ALIGN_CENTER: { textAlign: 'center' },
  };

  return contentLayoutXStyles[contentLayoutX];
});
