import OsanoFooterLink from '@root/inc.joinroot.com/src/components/footer/osano-footer-link';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import SmartLink from '@root/inc.joinroot.com/src/components/smart-link';
import environment from '@root/core/src/utils/environment';
import { StyleSheet } from '@root/core/src/utils/styles';
import { footerLinkStyles } from '@root/inc.joinroot.com/src/components/text/text-styles';

export default function FooterLinks({ footerLinks }) {
  const footerLinkColumns = footerLinks.reduce((columns, _, currentIndex, linkArray) => {
    if (currentIndex % 3 === 0) { columns.push(linkArray.slice(currentIndex, currentIndex + 3)); }
    return columns;
  }, []);

  return (
    <div css={styles.container}>
      {footerLinkColumns.map((column, i) => (
        <div
          css={styles.column}
          data-testid={'footer-link-column'}
          key={`column-${i}`}
        >
          {column.map((link, j) => (
            <SmartLink
              css={[footerLinkStyles, styles.link]}
              key={`link-${j}`}
              shouldTransformUrls={link.href !== 'https://inc.joinroot.com/careers/'} // ← Temporary: Careers needs to be an absolute link so that it takes the user to the failover bucket
              to={link.href}
            >
              {link.text}
            </SmartLink>
          ))}
        </div>
      ))}
      {environment.osanoEnabled === 'true' && <OsanoFooterLink />}
    </div>
  );
}

FooterLinks.propTypes = {
  footerLinks: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  })),
};

const styles = StyleSheet.create({
  container: {
    ...Responsive.md({
      flex: 1,
      display: 'flex',
      margin: '-5px auto 0 0',
      padding: '0 0 0 6vw',
    }),
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    ...Responsive.md({
      marginRight: '5vw',
    }),
  },
  link: {
    whiteSpace: 'nowrap',
    display: 'inline-block',
    marginLeft: -14,
    padding: '8px 14px',
    textDecoration: 'none',
    ...Responsive.md({
      marginLeft: -8,
      padding: '5px 8px',
    }),
  },
});
