import PropTypes from '@root/vendor/prop-types';
import { forwardRef } from '@root/vendor/react';
import { styled } from '@root/core/src/utils/styles';

const WrapRichText = forwardRef(({ children, className }, ref) => {
  if (!children || !Array.isArray(children)) { return null; }

  return (
    <Wrap
      className={className}
      ref={ref}
    >
      {children}
    </Wrap>
  );
});

WrapRichText.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
};

const Wrap = styled.div({
  '& > * + *': {
    marginTop: 10,
  },
  '& > h1 + h1, & > h2 + h2, & > h3 + h3, & > h4 + h4, & > h5 + h5': {
    marginTop: 0,
  },
  '& > blockquote': {
    margin: '50px 0',
  },
  '& > hr': {
    margin: '50px 0',
    width: '100%',
  },
});

export default WrapRichText;
