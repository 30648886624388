import { StyleSheet } from '@root/core/src/utils/styles';
import { footerTextStyles } from '@root/inc.joinroot.com/src/components/text/text-styles';

export default function FooterAddress() {
  return (
    <p
      css={styles.address}
      translate={'no'}
    >
      Root Inc.<br />
      80 E. Rich Street<br />
      Suite 500<br />
      Columbus, <abbr title={'Ohio'}>OH</abbr> 43215
    </p>
  );
}

const styles = StyleSheet.create({
  address: {
    ...footerTextStyles,
    '& abbr[title]': {
      textDecoration: 'none',
    },
  },
});
