import ArtDirectedGatsbyImage from '@root/inc.joinroot.com/src/components/art-directed-gatsby-image';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { BACKGROUND_COLOR } from '@root/inc.joinroot.com/src/components/page-sections/support/background-colors';
import { Colors, styled } from '@root/core/src/utils/styles';
import { IMAGE_HEIGHT, IMAGE_WIDTH } from '@root/inc.joinroot.com/src/components/page-sections/feature-image/section-options';

export default function FeatureImageSection({
  anchor,
  backgroundColor = BACKGROUND_COLOR['White'],
  image: {
    desktopImg,
    imageAltText,
    mobileImg,
    imageWidth = IMAGE_WIDTH['Large'],
    imageHeight = IMAGE_HEIGHT['Auto'],
  },
  sectionSpacing = {},
}) {
  return (
    <Section
      backgroundColor={backgroundColor}
      id={anchor}
      sectionSpacing={sectionSpacing}
    >
      <Image
        alt={imageAltText}
        desktopImg={desktopImg}
        imageHeight={imageHeight}
        imageWidth={imageWidth}
        mobileImg={mobileImg}
        responsiveBreakpoint={Responsive.QUERIES.lessThanMd}
      />
    </Section>
  );
}

FeatureImageSection.propTypes = {
  anchor: PropTypes.string,
  backgroundColor: PropTypes.oneOf(Object.values(BACKGROUND_COLOR)),
  image: PropTypes.shape({
    desktopImg: PropTypes.object.isRequired,
    mobileImg: PropTypes.object,
    imageAltText: PropTypes.string.isRequired,
    imageHeight: PropTypes.oneOf(Object.values(IMAGE_HEIGHT)),
    imageWidth: PropTypes.oneOf(Object.values(IMAGE_WIDTH)),
  }).isRequired,
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
};

const Section = styled.section({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}, ({ backgroundColor, sectionSpacing }) => {
  const sectionSpacingStyles = {
    ...Responsive.lessThanMd({
      paddingTop: sectionSpacing.top / 1.25,
      paddingBottom: sectionSpacing.bottom / 1.25,
    }),
    paddingTop: sectionSpacing.top,
    paddingBottom: sectionSpacing.bottom,
  };

  const backgroundColorStyles = {
    WHITE: { backgroundColor: Colors.white() },
    OFF_WHITE: { backgroundColor: Colors.nearWhite() },
  };

  return {
    ...sectionSpacingStyles,
    ...backgroundColorStyles[backgroundColor],
  };
});

const Image = styled(ArtDirectedGatsbyImage)(({ imageWidth, imageHeight }) => {
  const imageWidthStyles = {
    SMALL: { width: 'min(400px, 100%)' },
    LARGE: { width: 'min(930px, 100%)' },
    EXTRA_LARGE: { width: 'min(1200px, 100%)' },
    FULL_VIEWPORT: { width: '100vw' },
  };
  const imageHeightStyles = {
    AUTO: { height: 'auto' },
    SMALL: {
      height: 180,
      ...Responsive.md({ height: 330 }),
    },
    MEDIUM: {
      height: 260,
      ...Responsive.md({ height: 430 }),
    },
    LARGE: {
      height: 320,
      ...Responsive.md({ height: 600 }),
    },
    FULL_VIEWPORT: { height: '100vh' },
  };

  return {
    ...imageWidthStyles[imageWidth],
    ...imageHeightStyles[imageHeight],
  };
});
