export function isAnchorLink(href) {
  const anchor = /(^(.+)?\/?(.+\/)?#.*\/?$)/gi;

  return anchor.test(href);
}

export function isEmailAddressLink(href) {
  const emailAddress = /^(mailto:)/i;

  return emailAddress.test(href);
}

export function isAnyRootProjectLink(href) {
  const joinroot = /(^(http(s)?:\/\/)?(.*\.)?joinroot\.com)|(^\/.*\/?$)/gi;

  return joinroot.test(href);
}

export function isPdfLink(href) {
  const pdf = /(\.pdf\/?)$/i;

  return pdf.test(href);
}

export function isPhoneNumberLink(href) {
  const phoneNumber = /^(tel:)/i;

  return phoneNumber.test(href);
}

export function isOnelink(href) {
  const rootOnelink = /^http(s)?:\/\/root\.onelink\.me/;

  return rootOnelink.test(href);
}

export function isProjectLink(href) {
  if (href.startsWith('/') && !href.startsWith('//') || href.startsWith('#')) {
    // This is a relative or anchor URL, so we can assume it's a project link.
    return true;
  }

  const projectRegEx = /(^(http(s)?:\/\/)?(www*\.)?inc\.joinroot\.com)|(^\/.*\/?$)/gi;

  return projectRegEx.test(href);
}

export function isSamePathname(linkHref, currentPathname = '/') {
  if (linkHref.startsWith('#')) {
    return true;
  }

  let linkHrefPathname = linkHref.split('#')[0].replace(/^http(s)?:\/\/[^/]+\//i, '/').toLowerCase();

  if (linkHrefPathname === '') {
    linkHrefPathname = '/';
  }

  return linkHrefPathname === currentPathname.toLowerCase();
}
