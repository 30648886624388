import ExpandableSectionBlock from '@root/inc.joinroot.com/src/components/page-sections/expandable/expandable-section-block';
import FeatureImageSectionBlock from '@root/inc.joinroot.com/src/components/page-sections/feature-image/feature-image-section-block';
import FeatureImageWithContentSectionBlock from '@root/inc.joinroot.com/src/components/page-sections/feature-image-with-content/feature-image-with-content-section-block';
import RichTextSectionBlock from '@root/inc.joinroot.com/src/components/page-sections/rich-text/rich-text-section-block';
import SideBySideWithImageSectionBlock from '@root/inc.joinroot.com/src/components/page-sections/side-by-side-with-image/side-by-side-with-image-section-block';
import SideBySideWithToutsSectionBlock from '@root/inc.joinroot.com/src/components/page-sections/side-by-side-with-touts/side-by-side-with-touts-section-block';
import ToutSectionBlock from '@root/inc.joinroot.com/src/components/page-sections/tout-section/tout-section-block';

const blockComponentMap = {
  ['RICH_TEXT_SECTION']: RichTextSectionBlock,
  ['FEATURE_IMAGE_SECTION']: FeatureImageSectionBlock,
  ['FEATURE_IMAGE_WITH_CONTENT_SECTION']: FeatureImageWithContentSectionBlock,
  ['TOUT_SECTION']: ToutSectionBlock,
  ['EXPANDABLE_SECTION']: ExpandableSectionBlock,
  ['SIDE_BY_SIDE_WITH_TOUTS_SECTION']: SideBySideWithToutsSectionBlock,
  ['SIDE_BY_SIDE_WITH_IMAGE_SECTION']: SideBySideWithImageSectionBlock,
};

export default blockComponentMap;
