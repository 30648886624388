/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import environment from '@root/core/src/utils/environment';
import incWrapRootElement from '@root/inc.joinroot.com/src/inc-wrap-root-element';
import { RootIdService } from '@root/attribution/src/services/root-id-service';
import { datadogRum } from '@root/vendor/@datadog/browser-rum';
import 'focus-visible';
import 'intersection-observer';

export const onClientEntry = () => {
  if (environment.dataDogEnabled) {
    datadogRum.init({
      applicationId: environment.dataDogApplicationId,
      clientToken: environment.dataDogClientToken,
      site: 'datadoghq.com',
      service: 'inc.joinroot.com',
      env: environment.environment,
      version: environment.releaseSha,
      sampleRate: 100,
      trackInteractions: true,
      allowedTracingOrigins: [/https?:\/\/localhost(:\d+)?/, /^https?:\/\/app\.(.*\.)?joinroot.com(:\d+)?/, /^https?:\/\/root-staging\.joinroot.com(:\d+)?/],
      trackSessionAcrossSubdomains: true,
    });

    // eslint-disable-next-line root/prevent-use-of-window-location
    const urlParams = Object.fromEntries(new URLSearchParams(window.location.search));
    datadogRum.addRumGlobalContext('urlParameters', urlParams);

    const rootId = RootIdService.read();
    datadogRum.addRumGlobalContext('rootId', rootId);
  }
};

export const wrapRootElement = ({ element }) => {
  return incWrapRootElement({ element });
};
