import ButtonRenderer from '@root/inc.joinroot.com/src/components/buttons/button-renderer';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import WrapRichText from '@root/inc.joinroot.com/src/components/text/wrap-rich-text';
import { BUTTON_VARIANT } from '@root/inc.joinroot.com/src/components/buttons/button-options';

export default function MainContent({
  textContent,
  button,
}) {
  return (
    <>
      <WrapRichText>{textContent}</WrapRichText>
      {button && (
        <ButtonRenderer
          onClick={button.trackClick}
          to={button.link}
          variant={button.variant}
        >
          {button.text}
        </ButtonRenderer>
      )}
    </>
  );
}

MainContent.propTypes = {
  button: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string.isRequired,
    trackClick: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(Object.values(BUTTON_VARIANT)),
  }),
  textContent: PropTypes.arrayOf(PropTypes.node),
};
