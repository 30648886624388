import PropTypes from '@root/vendor/prop-types';

export default function CloseIcon({
  strokeColor = '#1d1d21',
  strokeWidth = 2,
  className,
}) {
  return (
    <svg
      className={className}
      fill={'none'}
      height={'24'}
      viewBox={'0 0 24 24'}
      width={'24'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <line
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        x1={'20.4854'}
        x2={'3.51486'}
        y1={'3.51375'}
        y2={'20.4843'}
      />
      <line
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        x1={'20.4853'}
        x2={'3.51471'}
        y1={'20.4845'}
        y2={'3.51389'}
      />
    </svg>
  );
}

CloseIcon.propTypes = {
  className: PropTypes.string,
  strokeColor: PropTypes.string,
  strokeWidth: PropTypes.number,
};
