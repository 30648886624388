import PropTypes from '@root/vendor/prop-types';
import SideBySideWithImageSection from '@root/inc.joinroot.com/src/components/page-sections/side-by-side-with-image/side-by-side-with-image-section';
import SideBySideWithImageSectionFullBleed from '@root/inc.joinroot.com/src/components/page-sections/side-by-side-with-image/side-by-side-with-image-section-full-bleed';
import buildButton from '@root/inc.joinroot.com/src/components/page-sections/support/build-button';
import renderRichText from '@root/inc.joinroot.com/src/components/page-sections/support/render-rich-text';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { BACKGROUND_COLOR } from '@root/inc.joinroot.com/src/components/page-sections/support/background-colors';
import { BUTTON_VARIANT } from '@root/inc.joinroot.com/src/components/buttons/button-options';
import { IMAGE_POSITION_X, IMAGE_SIZE, SECTION_LAYOUT_X, SECTION_LAYOUT_Y } from '@root/inc.joinroot.com/src/components/page-sections/side-by-side-with-image/section-options';
import { getImage } from 'gatsby-plugin-image';

function SideBySideWithImageSectionBlock({
  content: {
    id,
    sectionLayoutX,
    sectionLayoutY,
    backgroundColor,
    imageSize,
    imagePositionX,
    primaryImage,
    alternateMobileImage,
    imageAltText,
    sectionSpacing,
    sectionContent: {
      textContent,
      button,
    },
    anchor,
  },
}) {
  const { trackClick } = useAnalytics(`SIDE_BY_SIDE_WITH_IMAGE_SECTION_${id}`, false);

  if (isImageFullBleed(imageSize)) {
    return (
      <SideBySideWithImageSectionFullBleed
        anchor={anchor?.anchorSlug}
        backgroundColor={BACKGROUND_COLOR[backgroundColor]}
        image={{
          desktopImg: getImage(primaryImage),
          mobileImg: getImage(alternateMobileImage),
          imageAltText: imageAltText ?? '',
          imagePositionX: IMAGE_POSITION_X[imagePositionX],
        }}
        mainContent={{
          textContent: renderRichText(textContent),
          button: buildButton({
            button,
            trackClick,
          }),
        }}
        sectionLayoutX={SECTION_LAYOUT_X[sectionLayoutX]}
        sectionSpacing={sectionSpacing}
      />
    );
  }

  return (
    <SideBySideWithImageSection
      anchor={anchor?.anchorSlug}
      backgroundColor={BACKGROUND_COLOR[backgroundColor]}
      image={{
        desktopImg: getImage(primaryImage),
        mobileImg: getImage(alternateMobileImage),
        imageAltText: imageAltText ?? '',
      }}
      imageSize={IMAGE_SIZE[imageSize]}
      mainContent={{
        textContent: renderRichText(textContent),
        button: buildButton({
          button,
          trackClick,
        }),
      }}
      sectionLayoutX={SECTION_LAYOUT_X[sectionLayoutX]}
      sectionLayoutY={SECTION_LAYOUT_Y[sectionLayoutY]}
      sectionSpacing={sectionSpacing}
    />
  );
}

export default function BlockTestWrapper(props) {
  return (
    <div data-testid={'sideBySideWithImageSectionBlock'}>
      <SideBySideWithImageSectionBlock {...props} />
    </div>
  );
}

SideBySideWithImageSectionBlock.propTypes = {
  content: PropTypes.shape({
    anchor: PropTypes.shape({
      anchorSlug: PropTypes.string,
    }),
    id: PropTypes.string.isRequired,
    sectionSpacing: PropTypes.shape({
      bottom: PropTypes.number,
      top: PropTypes.number,
    }),
    backgroundColor: PropTypes.oneOf(Object.keys(BACKGROUND_COLOR)),
    sectionLayoutX: PropTypes.oneOf(Object.keys(SECTION_LAYOUT_X)),
    sectionLayoutY: PropTypes.oneOf(Object.keys(SECTION_LAYOUT_Y)),
    imageSize: PropTypes.oneOf(Object.keys(IMAGE_SIZE)),
    imagePositionX: PropTypes.oneOf(Object.keys(IMAGE_POSITION_X)),
    sectionContent: PropTypes.shape({
      textContent: PropTypes.shape({
        json: PropTypes.object.isRequired,
      }),
      button: PropTypes.shape({
        id: PropTypes.string.isRequired,
        variant: PropTypes.oneOf(Object.keys(BUTTON_VARIANT)).isRequired,
        text: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
      }),
    }),
    primaryImage: PropTypes.shape({
      gatsbyImageData: PropTypes.object.isRequired,
    }).isRequired,
    alternateMobileImage: PropTypes.shape({
      gatsbyImageData: PropTypes.object.isRequired,
    }),
    imageAltText: PropTypes.string,
  }).isRequired,
};

const isImageFullBleed = (imageSize) => IMAGE_SIZE[imageSize] === 'FULL_BLEED';

