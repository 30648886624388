import buildButton from '@root/inc.joinroot.com/src/components/page-sections/support/build-button';
import renderRichText from '@root/inc.joinroot.com/src/components/page-sections/support/render-rich-text';
import { MODAL_WIDTH } from '@root/inc.joinroot.com/src/components/modal/modal';
import { getImage } from 'gatsby-plugin-image';

export default function buildModal(modal) {
  if (!modal) { return; }

  const {
    id,
    modalWidth,
    image,
    imageAltText,
    textContent,
    button,
  } = modal;

  return {
    id,
    button: buildButton({ button }),
    image: getImage(image),
    textContent: renderRichText(textContent),
    imageAltText,
    modalWidth: MODAL_WIDTH[modalWidth],
  };
}
