import FeatureImageWithContentSection from '@root/inc.joinroot.com/src/components/page-sections/feature-image-with-content/feature-image-with-content-section';
import PropTypes from '@root/vendor/prop-types';
import buildButton from '@root/inc.joinroot.com/src/components/page-sections/support/build-button';
import renderRichText from '@root/inc.joinroot.com/src/components/page-sections/support/render-rich-text';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { BACKGROUND_COLOR } from '@root/inc.joinroot.com/src/components/page-sections/support/background-colors';
import { BUTTON_VARIANT } from '@root/inc.joinroot.com/src/components/buttons/button-options';
import { CONTENT_COLOR, CONTENT_LAYOUT_X, IMAGE_HEIGHT } from '@root/inc.joinroot.com/src/components/page-sections/feature-image-with-content/section-options';
import { getImage } from 'gatsby-plugin-image';

export default function FeatureImageWithContentSectionBlock({
  content: {
    id,
    backgroundColor,
    primaryImage,
    alternateMobileImage,
    imageAltText,
    sectionSpacing,
    imageHeight,
    contentColor,
    contentLayoutX,
    sectionContent: {
      textContent,
      button,
    },
    anchor,
  },
}) {
  const { trackClick } = useAnalytics(`FEATURE_IMAGE_WITH_CONTENT_SECTION_${id}`, false);

  return (
    <FeatureImageWithContentSection
      anchor={anchor?.anchorSlug}
      backgroundColor={BACKGROUND_COLOR[backgroundColor]}
      contentColor={CONTENT_COLOR[contentColor]}
      contentLayoutX={CONTENT_LAYOUT_X[contentLayoutX]}
      image={{
        desktopImg: getImage(primaryImage),
        mobileImg: getImage(alternateMobileImage),
        imageAltText: imageAltText ?? '',
      }}
      imageHeight={IMAGE_HEIGHT[imageHeight]}
      mainContent={{
        textContent: renderRichText(textContent),
        button: buildButton({
          button,
          trackClick,
        }),
      }}
      sectionSpacing={sectionSpacing}
    />
  );
}

FeatureImageWithContentSectionBlock.propTypes = {
  content: PropTypes.shape({
    anchor: PropTypes.shape({
      anchorSlug: PropTypes.string,
    }),
    contentColor: PropTypes.oneOf(Object.keys(CONTENT_COLOR)),
    contentLayoutX: PropTypes.oneOf(Object.keys(CONTENT_LAYOUT_X)),
    sectionSpacing: PropTypes.shape({
      bottom: PropTypes.number,
      top: PropTypes.number,
    }),
    sectionContent: PropTypes.shape({
      textContent: PropTypes.shape({
        json: PropTypes.object.isRequired,
      }),
      button: PropTypes.shape({
        id: PropTypes.string.isRequired,
        variant: PropTypes.oneOf(Object.keys(BUTTON_VARIANT)).isRequired,
        text: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
      }),
    }),
    id: PropTypes.string.isRequired,
    imageHeight: PropTypes.oneOf(Object.keys(IMAGE_HEIGHT)),
    backgroundColor: PropTypes.oneOf(Object.keys(BACKGROUND_COLOR)),
    primaryImage: PropTypes.shape({
      gatsbyImageData: PropTypes.object.isRequired,
    }).isRequired,
    alternateMobileImage: PropTypes.shape({
      gatsbyImageData: PropTypes.object.isRequired,
    }),
    imageAltText: PropTypes.string,
  }).isRequired,
};

