/* eslint-disable object-property-newline */
/* eslint-disable object-curly-newline */

export const spacingSizeMap = {
  XL: 300,
  L: 200,
  M: 100,
  S: 50,
  XS: 25,
  'No space': 0,
};

const basicPageDefaults = {
  defaultSpacing: { top: 100, bottom: 100 },
  specialCases: {
    featureImageWithContentSection: { top: 0, bottom: 0 },
    featureImageSection: { top: 0, bottom: 0 },
  },
};

const pageDefaultsMap = {
  BASIC_PAGE: basicPageDefaults,
};

function setDefaultSpacing(block, pageType) {
  const pageDefaults = pageDefaultsMap[pageType];
  const currentBlockId = block.sys.contentType.sys.id;

  for (const [blockId, blockSpecificDefaults] of Object.entries(pageDefaults.specialCases)) {
    if (currentBlockId === blockId) {
      return {
        ...block,
        sectionSpacing: { ...blockSpecificDefaults },
      };
    }
  }

  return {
    ...block,
    sectionSpacing: { ...pageDefaults.defaultSpacing },
  };
}

const getSpacerBlock = (block) => {
  const selectedBlock = block?.sys.contentType.sys.id === 'sectionSpacer';
  return selectedBlock ? { ...block } : null;
};

export default function calculateSectionSpacing(blocks, pageType = 'BASIC_PAGE') {
  // 1. Modify spacer blocks - Assign height values to spacer blocks based on spacer rules.
  const blocksWithSpacingCalculations = blocks.map((block, i) => {
    const currentBlockIsSpacer = getSpacerBlock(block);
    if (!currentBlockIsSpacer) { return block; }

    const prevBlockIsSpacer = getSpacerBlock(blocks[i - 1]);
    const nextBlockIsSpacer = getSpacerBlock(blocks[i + 1]);
    const isFirstBlock = i === 0;
    const isLastBlock = i === blocks.length - 1;

    const spacerBlock = { ...block };

    if (prevBlockIsSpacer || nextBlockIsSpacer || isFirstBlock || isLastBlock) {
      spacerBlock.calculatedSpacing = spacingSizeMap[block.size];
    } else {
      spacerBlock.calculatedSpacing = spacingSizeMap[block.size] / 2;
    }

    return spacerBlock;
  });

  // 2. Modify section blocks - Supply top/bottom space values to section blocks.
  // 3. Return all blocks that are not spacer blocks.
  const sectionsWithSpaceApplied = blocksWithSpacingCalculations
    .map((block, i) => {
      const currentBlockIsSpacer = getSpacerBlock(block);
      if (currentBlockIsSpacer) { return block; }

      const prevBlockIsSpacer = getSpacerBlock(blocksWithSpacingCalculations[i - 1]);
      const nextBlockIsSpacer = getSpacerBlock(blocksWithSpacingCalculations[i + 1]);

      const sectionBlock = setDefaultSpacing(block, pageType);

      if (prevBlockIsSpacer) {
        sectionBlock.sectionSpacing.top = prevBlockIsSpacer.calculatedSpacing;
      }
      if (nextBlockIsSpacer) {
        sectionBlock.sectionSpacing.bottom = nextBlockIsSpacer.calculatedSpacing;
      }

      return sectionBlock;
    })
    .filter((block) => {
      const currentBlockIsSpacer = getSpacerBlock(block);
      return !currentBlockIsSpacer;
    });

  return sectionsWithSpaceApplied;
}
