import IconTout from '@root/inc.joinroot.com/src/components/page-sections/side-by-side-with-touts/icon-tout';
import PropTypes from '@root/vendor/prop-types';
import SideBySideWithToutsSection from '@root/inc.joinroot.com/src/components/page-sections/side-by-side-with-touts/side-by-side-with-touts-section';
import buildButton from '@root/inc.joinroot.com/src/components/page-sections/support/build-button';
import renderRichText from '@root/inc.joinroot.com/src/components/page-sections/support/render-rich-text';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { BACKGROUND_COLOR } from '@root/inc.joinroot.com/src/components/page-sections/support/background-colors';
import { BUTTON_VARIANT } from '@root/inc.joinroot.com/src/components/buttons/button-options';
import { DIVIDER_POSITION_X, ICON_SIZE, TOUT_LAYOUT } from '@root/inc.joinroot.com/src/components/page-sections/side-by-side-with-touts/section-options';
import { getImage } from 'gatsby-plugin-image';

export default function SideBySideWithToutsSectionBlock({
  content: {
    id,
    backgroundColor,
    dividerPositionX,
    toutLayout,
    toutIconSize,
    sectionContent: {
      textContent,
      button,
    },
    touts,
    sectionSpacing,
    anchor,
  },
}) {
  const { trackClick } = useAnalytics(`SIDE_BY_SIDE_WITH_TOUTS_SECTION_${id}`, false);

  return (
    <SideBySideWithToutsSection
      anchor={anchor?.anchorSlug}
      backgroundColor={BACKGROUND_COLOR[backgroundColor]}
      dividerPositionX={DIVIDER_POSITION_X[dividerPositionX]}
      mainContent={{
        textContent: renderRichText(textContent),
        button: buildButton({
          button,
          trackClick,
        }),
      }}
      sectionSpacing={sectionSpacing}
      toutLayout={TOUT_LAYOUT[toutLayout]}
    >
      {touts.map((tout, i) => (
        <IconTout
          key={i}
          toutContent={{
            imageData: {
              image: getImage(tout.image),
              contentType: tout.image?.file?.contentType,
              iconUrl: tout.image?.file?.url,
              imageAltText: tout.imageAltText ?? '',
              iconSize: ICON_SIZE[toutIconSize],
            },
            textContent: renderRichText(tout.textContent),
          }}
          toutLayout={TOUT_LAYOUT[toutLayout]}
        />
      ))}
    </SideBySideWithToutsSection>
  );
}

SideBySideWithToutsSectionBlock.propTypes = {
  content: PropTypes.shape({
    anchor: PropTypes.shape({
      anchorSlug: PropTypes.string,
    }),
    id: PropTypes.string.isRequired,
    sectionSpacing: PropTypes.shape({
      bottom: PropTypes.number,
      top: PropTypes.number,
    }),
    backgroundColor: PropTypes.oneOf(Object.keys(BACKGROUND_COLOR)),
    dividerPositionX: PropTypes.oneOf(Object.keys(DIVIDER_POSITION_X)),
    toutLayout: PropTypes.oneOf(Object.keys(TOUT_LAYOUT)),
    toutIconSize: PropTypes.oneOf(Object.keys(ICON_SIZE)),
    touts: PropTypes.arrayOf(
      PropTypes.object.isRequired,
    ).isRequired,
    sectionContent: PropTypes.shape({
      textContent: PropTypes.shape({
        json: PropTypes.object.isRequired,
      }),
      button: PropTypes.shape({
        id: PropTypes.string.isRequired,
        variant: PropTypes.oneOf(Object.keys(BUTTON_VARIANT)).isRequired,
        text: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
};
