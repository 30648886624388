// Reference: https://css-tricks.com/easing-linear-gradients/
export const monochromeEasing = (lightness = 100) => `
  hsl(0, 0%, ${lightness}%) 0%,
  hsla(0, 0%, ${lightness}%, 0.987) 8.1%,
  hsla(0, 0%, ${lightness}%, 0.951) 15.5%,
  hsla(0, 0%, ${lightness}%, 0.896) 22.4%,
  hsla(0, 0%, ${lightness}%, 0.825) 28.9%,
  hsla(0, 0%, ${lightness}%, 0.741) 35%,
  hsla(0, 0%, ${lightness}%, 0.648) 41%,
  hsla(0, 0%, ${lightness}%, 0.55) 46.7%,
  hsla(0, 0%, ${lightness}%, 0.45) 52.5%,
  hsla(0, 0%, ${lightness}%, 0.352) 58.3%,
  hsla(0, 0%, ${lightness}%, 0.259) 64.3%,
  hsla(0, 0%, ${lightness}%, 0.175) 70.5%,
  hsla(0, 0%, ${lightness}%, 0.104) 77.1%,
  hsla(0, 0%, ${lightness}%, 0.049) 84.2%,
  hsla(0, 0%, ${lightness}%, 0.013) 91.8%,
  hsla(0, 0%, ${lightness}%, 0) 100%
`;
