import ChevronDown from '@root/inc.joinroot.com/src/assets/icons/components/chevron-down';
import PropTypes from '@root/vendor/prop-types';
import React, { useRef, useState } from '@root/vendor/react';
import WrapRichText from '@root/inc.joinroot.com/src/components/text/wrap-rich-text';
import { Colors, styled } from '@root/core/src/utils/styles';
import { H4 } from '@root/inc.joinroot.com/src/components/text/text-elements';

export default function Expandable({
  anchor,
  title,
  children,
}) {
  const [expanded, setExpanded] = useState(false);
  const contentElementRef = useRef(null);

  return (
    <Wrap id={anchor}>
      <DrawerToggle onClick={() => setExpanded(!expanded)}>
        <Title expanded={expanded}>{title}</Title>
        <Arrow expanded={expanded} />
      </DrawerToggle>
      <Drawer
        expanded={expanded}
        expandedHeight={contentElementRef.current?.clientHeight || 0}
      >
        <RichTextContent ref={contentElementRef}>{children}</RichTextContent>
      </Drawer>
    </Wrap>
  );
}

Expandable.propTypes = {
  anchor: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  title: PropTypes.string.isRequired,
};

const Wrap = styled.div({
  borderBottom: `1px solid ${Colors.gray30()}`,
});

const DrawerToggle = styled.button({
  appearance: 'none',
  border: 'none',
  backgroundColor: 'transparent',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px 10px',
  cursor: 'pointer',
  '&:focus h4': {
    color: Colors.rootOrange(),
  },
  '&:focus svg path': {
    stroke: Colors.rootOrange(),
  },
  '@media(hover: hover) and (pointer: fine)': {
    '&:hover > h4': {
      color: Colors.rootOrange(),
    },
    '&:hover svg path': {
      stroke: Colors.rootOrange(),
    },
  },
});

const Title = styled(H4)({
  marginRight: 50,
  color: Colors.nearBlack(),
  transition: '150ms ease-in-out',
  userSelect: 'none',
}, ({ expanded }) => expanded && { color: Colors.rootOrange() });

const Arrow = styled(ChevronDown)({
  flexShrink: 0,
  transition: 'transform 150ms ease-in-out',
  '& path': {
    stroke: Colors.nearBlack(),
    transition: '150ms ease-in-out',
  },
}, ({ expanded }) => expanded && {
  transform: 'rotate(-180deg)',
  '& path': {
    stroke: Colors.rootOrange(),
  },
});

const Drawer = styled.div({
  height: 0,
  opacity: 0,
  overflowY: 'hidden',
  visibility: 'hidden',
  transition: 'all 250ms cubic-bezier(0.37, 0, 0.28, 1), opacity 350ms cubic-bezier(0.38, 0, 0.28, 1)',
}, ({ expanded, expandedHeight }) => expanded && {
  height: expandedHeight,
  opacity: 1,
  visibility: 'visible',
});

const RichTextContent = styled(WrapRichText)({
  padding: '10px 10px 20px',
});
