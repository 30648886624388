import MainContent from '@root/inc.joinroot.com/src/components/main-content';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { BACKGROUND_COLOR } from '@root/inc.joinroot.com/src/components/page-sections/support/background-colors';
import { Colors, styled } from '@root/core/src/utils/styles';
import { DIVIDER_POSITION_X, TOUT_LAYOUT } from '@root/inc.joinroot.com/src/components/page-sections/side-by-side-with-touts/section-options';

export default function SideBySideWithToutsSection({
  anchor,
  mainContent,
  backgroundColor = BACKGROUND_COLOR['White'],
  dividerPositionX = DIVIDER_POSITION_X['Divider left'],
  sectionSpacing = {},
  toutLayout = TOUT_LAYOUT['Grid'],
  children,
}) {
  return (
    <Section
      backgroundColor={backgroundColor}
      id={anchor}
      sectionSpacing={sectionSpacing}
    >
      <Wrap>
        <MainContentContainer
          data-testid={'main-content-container'}
          dividerPositionX={dividerPositionX}
        >
          <MainContent {...mainContent} />
        </MainContentContainer>
        <ToutContainer
          data-testid={'tout-container'}
          toutLayout={toutLayout}
        >
          {children}
        </ToutContainer>
      </Wrap>
    </Section>
  );
}

SideBySideWithToutsSection.propTypes = {
  anchor: PropTypes.string,
  backgroundColor: PropTypes.oneOf(Object.values(BACKGROUND_COLOR)),
  children: PropTypes.arrayOf(PropTypes.node),
  dividerPositionX: PropTypes.oneOf(Object.values(DIVIDER_POSITION_X)),
  mainContent: PropTypes.object.isRequired,
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
  toutLayout: PropTypes.oneOf(Object.values(TOUT_LAYOUT)),
};

const Section = styled.section({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  padding: '100px 20px',
  ...Responsive.lg({
    padding: '100px 8%',
  }),
}, ({ backgroundColor, sectionSpacing }) => {
  const sectionSpacingStyles = {
    ...Responsive.lessThanMd({
      paddingTop: sectionSpacing.top / 1.25,
      paddingBottom: sectionSpacing.bottom / 1.25,
    }),
    paddingTop: sectionSpacing.top,
    paddingBottom: sectionSpacing.bottom,
  };

  const backgroundColorStyles = {
    WHITE: { backgroundColor: Colors.white() },
    OFF_WHITE: { backgroundColor: Colors.nearWhite() },
  };

  return {
    ...sectionSpacingStyles,
    ...backgroundColorStyles[backgroundColor],
  };
});

const Wrap = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 'min(1200px, 100%)',
  ...Responsive.lg({
    flexDirection: 'row',
  }),
});

const MainContentContainer = styled.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  maxWidth: 800,
  marginBottom: 60,
  ...Responsive.lg({
    alignItems: 'flex-start',
    textAlign: 'left',
    paddingRight: 'min(120px, 8vw)',
    marginBottom: 0,
  }),
  '& > * + *': { marginTop: 14 },
  '& > a': { marginTop: 35 },
}, ({ dividerPositionX }) => {
  const dividerPositionXStyles = {
    DIVIDER_LEFT: {
      ...Responsive.lg({ flex: 0.45 }),
    },
    DIVIDER_CENTER: {
      ...Responsive.lg({ flex: 1 }),
    },
    DIVIDER_RIGHT: {
      ...Responsive.lg({ flex: 1.5 }),
    },
  };

  return dividerPositionXStyles[dividerPositionX];
});

const ToutContainer = styled.div({
  flex: 1,
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridGap: '40px',
  ...Responsive.lg({
    paddingLeft: 'min(120px, 8vw)',
    borderLeft: `1px solid ${Colors.gray30()}`,
  }),
}, ({ toutLayout }) => {
  const toutLayoutStyles = {
    LIST: {}, // covered by base styles
    GRID: {
      ...Responsive.lg({
        gridTemplateColumns: '1fr 1fr',
        gridGap: '50px 10%',
      }),
    },
  };

  return toutLayoutStyles[toutLayout];
});
