import PropTypes from '@root/vendor/prop-types';
import SmartLink from '@root/inc.joinroot.com/src/components/smart-link';
import { Colors, StyleSheet, styled } from '@root/core/src/utils/styles';

export default function NavLinks({ navLinks, isNavMenuOpen }) {
  return (
    <Menu
      data-testid={'mobile-nav-menu'}
      isNavMenuOpen={isNavMenuOpen}
    >
      <ul css={styles.ul}>
        {navLinks.map((link, i) => (
          <Link
            index={i}
            isNavMenuOpen={isNavMenuOpen}
            key={i}
            shouldTransformUrls={link.href !== 'https://inc.joinroot.com/careers/'} // ← Temporary: Careers needs to be an absolute link so that it takes the user to the failover bucket
            to={link.href}
          >
            {link.text}
          </Link>
        ))}
      </ul>
    </Menu>
  );
}

NavLinks.propTypes = {
  isNavMenuOpen: PropTypes.bool.isRequired,
  navLinks: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  })),
};

const Menu = styled.div({
  width: '100%',
  height: 0,
  backgroundColor: Colors.nearBlack(),
  overflow: 'hidden',
  transition: 'height 500ms cubic-bezier(.37, 0, .28, 1)',
}, ({ isNavMenuOpen }) => isNavMenuOpen && {
  height: 'calc(100vh - 50px)',
});

const Link = styled(SmartLink, {
  shouldForwardProp: (prop) => prop !== 'isNavMenuOpen',
})({
  padding: 20,
  color: Colors.white(),
  fontSize: '20px',
  transition: 'transform 350ms cubic-bezier(0.37, 0, 0.28, 1), opacity 500ms ease-in-out',
  transform: 'translate3d(-250px, 0, 0)',
  opacity: 0,
  '&:active': { backgroundColor: Colors.toRgba(Colors.black(), 0.3) },
}, ({ isNavMenuOpen, index }) => {
  return isNavMenuOpen && {
    transitionDelay: `${index * 50}ms`,
    transform: 'translate3d(0, 0, 0)',
    opacity: 1,
  };
});

const styles = StyleSheet.create({
  ul: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 10,
    borderTop: `1px solid ${Colors.toRgba(Colors.white(), 0.15)}`,
    listStyle: 'none',
  },
});

