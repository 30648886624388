import Responsive from '@root/core/src/utils/responsive';
import { Colors } from '@root/core/src/utils/styles';
import { regular, semiBold } from '@root/brand/src/assets/fonts/root-sans/emotion';

export const heading1Styles = {
  ...semiBold(),
  color: Colors.nearBlack(),
  fontSize: ['42px', 'clamp(42px, 6vw, 66px)'],
  letterSpacing: '-0.01em',
  lineHeight: 1,
};

export const heading2Styles = {
  ...semiBold(),
  color: Colors.nearBlack(),
  fontSize: ['31px', 'clamp(31px, 4.5vw, 39px)'],
  letterSpacing: '-0.01em',
  lineHeight: 1.1,
};

export const heading3Styles = {
  ...semiBold(),
  color: Colors.nearBlack(),
  fontSize: ['27px', 'clamp(27px, 3.5vw, 30px)'],
  letterSpacing: '-0.01em',
  lineHeight: 1.1,
};

export const heading4Styles = {
  ...semiBold(),
  color: Colors.nearBlack(),
  fontSize: ['22px', 'clamp(22px, 3vw, 25px)'],
  letterSpacing: '-0.01em',
  lineHeight: 1.3,
};

export const heading5Styles = {
  ...regular(),
  color: Colors.nearBlack(),
  fontSize: 20,
  letterSpacing: '-0.015em',
  lineHeight: 1.3,
};

export const body1Styles = {
  ...regular(),
  whiteSpace: 'pre-wrap',
  color: Colors.gray50(),
  fontSize: 18,
  letterSpacing: '-0.015em',
  lineHeight: 1.3,
};

export const body2Styles = {
  ...regular(),
  whiteSpace: 'pre-wrap',
  color: Colors.gray50(),
  fontSize: 14,
  letterSpacing: '-0.015em',
  lineHeight: 1.3,
};

export const body3Styles = {
  ...regular(),
  whiteSpace: 'pre-wrap',
  color: Colors.gray50(),
  fontSize: 12,
  letterSpacing: '-0.015em',
  lineHeight: 1.4,
};

export const uLStyles = {
  color: Colors.nearBlack(),
  paddingLeft: 28,
  textAlign: 'left',
  '& li p': {
    marginLeft: 4,
  },
};

export const oLStyles = {
  color: Colors.nearBlack(),
  paddingLeft: 28,
  textAlign: 'left',
  '& li p': {
    marginLeft: 10,
  },
};

export const lIStyles = {
  '& p': {
    margin: '10px 0',
  },
};

export const blockquoteStyles = {
  padding: '0 0 0 20px',
  borderLeft: `1px solid ${Colors.rootOrange()}`,
  '& p:first-of-type': {
    color: Colors.nearBlack(),
  },
};

export const footerTextStyles = {
  ...regular(),
  color: Colors.gray40(),
  fontSize: 17,
  lineHeight: 1.5,
  ...Responsive.md({
    fontSize: 15,
  }),
};

const whiteLinkStyles = {
  color: Colors.white(),
  cursor: 'pointer',
  transition: '150ms ease-in-out',
  '&:hover': {
    color: Colors.gray40(),
  },
  '&:active': {
    color: Colors.gray50(),
  },
};

const blackLinkStyles = {
  color: Colors.nearBlack(),
  cursor: 'pointer',
  transition: '150ms ease-in-out',
  '&:hover': {
    color: Colors.rootOrange(),
  },
  '&:active': {
    color: Colors.toRgba(Colors.rootOrange(), 0.7),
  },
};

export const bodyLinkStyles = {
  ...body1Styles,
  ...blackLinkStyles,
  textDecoration: 'underline',
};

export const footerLinkStyles = {
  ...footerTextStyles,
  ...whiteLinkStyles,
};

