import PropTypes from '@root/vendor/prop-types';
import ToutSectionStacked from '@root/inc.joinroot.com/src/components/page-sections/tout-section/tout-section-stacked';
import ToutStacked from '@root/inc.joinroot.com/src/components/page-sections/tout-section/tout-stacked';
import buildButton from '@root/inc.joinroot.com/src/components/page-sections/support/build-button';
import buildModal from '@root/inc.joinroot.com/src/components/page-sections/support/build-modal';
import renderRichText from '@root/inc.joinroot.com/src/components/page-sections/support/render-rich-text';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { BACKGROUND_COLOR } from '@root/inc.joinroot.com/src/components/page-sections/support/background-colors';
import { ICON_SIZE, TOUT_COLUMN_COUNT, TOUT_VARIANT } from '@root/inc.joinroot.com/src/components/page-sections/tout-section/tout-options';
import { getImage } from 'gatsby-plugin-image';

export default function ToutSectionBlock({
  content: {
    id,
    backgroundColor,
    toutColumnCount,
    toutVariant,
    toutIconSize,
    touts,
    sectionSpacing,
    anchor,
  },
}) {
  const { trackClick } = useAnalytics(`TOUT_SECTION_${id}`, false);

  return (
    <ToutSectionStacked
      anchor={anchor?.anchorSlug}
      backgroundColor={BACKGROUND_COLOR[backgroundColor]}
      sectionSpacing={sectionSpacing}
    >
      {touts.map((tout, i) => (
        <ToutStacked
          key={i}
          toutColumnCount={TOUT_COLUMN_COUNT[toutColumnCount]}
          toutContent={{
            imageData: {
              image: getImage(tout.image),
              contentType: tout.image?.file?.contentType,
              iconUrl: tout.image?.file?.url,
              imageAltText: tout.imageAltText ?? '',
              iconSize: ICON_SIZE[toutIconSize],
            },
            textContent: renderRichText(tout.textContent),
            button: buildButton({
              button: tout.button,
              trackClick,
            }),
            modal: buildModal(tout.modal),
            id: tout.id,
          }}
          toutVariant={TOUT_VARIANT[toutVariant]}
        />
      ))}
    </ToutSectionStacked>
  );
}

ToutSectionBlock.propTypes = {
  content: PropTypes.shape({
    anchor: PropTypes.shape({
      anchorSlug: PropTypes.string,
    }),
    id: PropTypes.string.isRequired,
    sectionSpacing: PropTypes.shape({
      bottom: PropTypes.number,
      top: PropTypes.number,
    }),
    backgroundColor: PropTypes.oneOf(Object.keys(BACKGROUND_COLOR)),
    touts: PropTypes.arrayOf(
      PropTypes.object.isRequired,
    ).isRequired,
    toutColumnCount: PropTypes.oneOf(Object.keys(TOUT_COLUMN_COUNT)),
    toutVariant: PropTypes.oneOf(Object.keys(TOUT_VARIANT)),
    toutIconSize: PropTypes.oneOf(Object.keys(ICON_SIZE)),
  }),
};
