import PropTypes from '@root/vendor/prop-types';
import { Colors, styled } from '@root/core/src/utils/styles';

export default function NavMenuButton({ toggleNavOpen, isNavMenuOpen }) {
  return (
    <MenuButton
      data-testid={'mobile-nav-menu-button'}
      isNavMenuOpen={isNavMenuOpen}
      onClick={toggleNavOpen}
    >
      <div className={'line'} />
      <div className={'line'} />
      <div className={'line'} />
    </MenuButton>
  );
}

NavMenuButton.propTypes = {
  isNavMenuOpen: PropTypes.bool.isRequired,
  toggleNavOpen: PropTypes.func.isRequired,
};

const MenuButton = styled.button({
  display: 'block',
  backgroundColor: 'transparent',
  padding: 10,
  border: 'none',
  outline: 'none',
  '& .line': {
    width: 25,
    height: 2,
    margin: '6px 0',
    backgroundColor: Colors.white(),
    transition: 'all 400ms cubic-bezier(.37, 0, .28, 1)',
  },
}, ({ isNavMenuOpen }) => isNavMenuOpen && {
  '& .line:nth-of-type(1)': {
    transform: 'translateY(8px) rotate(225deg)',
  },
  '& .line:nth-of-type(2)': {
    opacity: 0,
  },
  '& .line:nth-of-type(3)': {
    transform: 'translateY(-8px) rotate(-45deg)',
  },
});

