import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { StyleSheet } from '@root/core/src/utils/styles';
import { footerLinkStyles } from '@root/inc.joinroot.com/src/components/text/text-styles';

export default function OsanoFooterLink() {
  const toggleOsanoSidebar = () => {
    if (window.Osano.cm.drawerOpen) {
      window.Osano.cm.hideDrawer('osano-cm-dom-info-dialog-open');
    } else {
      window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
    }
  };

  return (
    <a
      css={[
        footerLinkStyles, styles.link,
      ]}
      onClick={toggleOsanoSidebar}
    >
      Manage Cookies
    </a>
  );
}

OsanoFooterLink.propTypes = {
  isDarkMode: PropTypes.bool,
};

const styles = StyleSheet.create({
  link: {
    whiteSpace: 'nowrap',
    display: 'inline-block',
    marginLeft: -14,
    padding: '8px 14px',
    textDecoration: 'none',
    ...Responsive.md({
      marginLeft: -8,
      padding: '5px 8px',
    }),
  },
});
