import FeatureImageSection from '@root/inc.joinroot.com/src/components/page-sections/feature-image/feature-image-section';
import PropTypes from '@root/vendor/prop-types';
import { BACKGROUND_COLOR } from '@root/inc.joinroot.com/src/components/page-sections/support/background-colors';
import { IMAGE_HEIGHT, IMAGE_WIDTH } from '@root/inc.joinroot.com/src/components/page-sections/feature-image/section-options';
import { getImage } from 'gatsby-plugin-image';

export default function FeatureImageSectionBlock({
  content: {
    backgroundColor,
    primaryImage,
    alternateMobileImage,
    imageAltText,
    sectionSpacing,
    imageWidth,
    imageHeight,
    anchor,
  },
}) {
  return (
    <FeatureImageSection
      anchor={anchor?.anchorSlug}
      backgroundColor={BACKGROUND_COLOR[backgroundColor]}
      image={{
        desktopImg: getImage(primaryImage),
        mobileImg: getImage(alternateMobileImage),
        imageAltText: imageAltText ?? '',
        imageHeight: IMAGE_HEIGHT[imageHeight],
        imageWidth: IMAGE_WIDTH[imageWidth],
      }}
      sectionSpacing={sectionSpacing}
    />
  );
}

FeatureImageSectionBlock.propTypes = {
  content: PropTypes.shape({
    anchor: PropTypes.shape({
      anchorSlug: PropTypes.string,
    }),
    sectionSpacing: PropTypes.shape({
      bottom: PropTypes.number,
      top: PropTypes.number,
    }),
    imageHeight: PropTypes.oneOf(Object.keys(IMAGE_HEIGHT)),
    imageWidth: PropTypes.oneOf(Object.keys(IMAGE_WIDTH)),
    backgroundColor: PropTypes.oneOf(Object.keys(BACKGROUND_COLOR)),
    primaryImage: PropTypes.shape({
      gatsbyImageData: PropTypes.object.isRequired,
    }).isRequired,
    alternateMobileImage: PropTypes.shape({
      gatsbyImageData: PropTypes.object.isRequired,
    }),
    imageAltText: PropTypes.string,
  }).isRequired,
};

