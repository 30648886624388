export const IMAGE_HEIGHT = {
  Medium: 'MEDIUM',
  Large: 'LARGE',
  'Full viewport': 'FULL_VIEWPORT',
};

export const CONTENT_LAYOUT_X = {
  'Align left': 'ALIGN_LEFT',
  'Align center': 'ALIGN_CENTER',
};

export const CONTENT_COLOR = {
  Light: 'LIGHT',
  Dark: 'DARK',
};
