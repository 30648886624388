import NavDesktop from '@root/inc.joinroot.com/src/components/nav/nav-desktop/nav-desktop';
import NavMobile from '@root/inc.joinroot.com/src/components/nav/nav-mobile/nav-mobile';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import useScrollPosition from '@root/core/src/hooks/use-scroll-position';
import { styled } from '@root/core/src/utils/styles';

export default function Nav({ navLinks }) {
  const [isNavHidden, setIsNavHidden] = useState(false);

  useScrollPosition(({ previousPosition, currentPosition }) => {
    const isAtPageTop = currentPosition.y > -50;

    const shouldHideNav = currentPosition.y < previousPosition.y && !isAtPageTop;
    setIsNavHidden(shouldHideNav);
  }, []);

  return (
    <>
      <MobileNav
        isNavHidden={isNavHidden}
        navLinks={navLinks}
      />
      <DesktopNav
        isNavHidden={isNavHidden}
        navLinks={navLinks}
      />
    </>
  );
}

Nav.propTypes = {
  navLinks: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  })),
};

const MobileNav = styled(NavMobile)({
  ...Responsive.md({
    display: 'none',
  }),
});

const DesktopNav = styled(NavDesktop)({
  ...Responsive.lessThanMd({
    display: 'none',
  }),
});
