import RootIncLogo from '@root/brand/src/components/root-inc-logo';
import SmartLink from '@root/inc.joinroot.com/src/components/smart-link';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function FooterLogo() {
  return (
    <SmartLink
      css={styles.logoLink}
      to={'/'}
    >
      <RootIncLogo logoStyles={styles.logo} />
    </SmartLink>
  );
}

const styles = StyleSheet.create({
  logoLink: {
    display: 'inline-block',
    padding: 6,
    marginLeft: -6,
    '&:hover svg': {
      fill: Colors.gray40(),
    },
    '&:active svg': {
      fill: Colors.gray50(),
    },
  },
  logo: {
    display: 'block',
    width: 'auto',
    height: 18,
  },
});
