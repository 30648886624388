import PropTypes from '@root/vendor/prop-types';
import WrapRichText from '@root/inc.joinroot.com/src/components/text/wrap-rich-text';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ICON_SIZE, TOUT_LAYOUT } from '@root/inc.joinroot.com/src/components/page-sections/side-by-side-with-touts/section-options';
import { styled } from '@root/core/src/utils/styles';

export default function IconTout({
  toutContent: {
    textContent,
    imageData: {
      image,
      contentType,
      iconUrl,
      imageAltText,
      iconSize = ICON_SIZE['Small'],
    },
  },
  toutLayout = TOUT_LAYOUT['Grid'],
}) {
  const renderIcon = contentType === 'image/svg+xml' && iconUrl;
  const renderImage = contentType !== 'image/svg+xml' && image;

  return (
    <Tout toutLayout={toutLayout}>
      {renderIcon && (
        <Icon
          alt={imageAltText}
          className={'tout-icon'}
          iconSize={iconSize}
          loading={'lazy'}
          src={iconUrl}
        />
      )}
      {renderImage && (
        <Image
          alt={imageAltText}
          className={'tout-image'}
          iconSize={iconSize}
          image={image}
          imgStyle={{ objectFit: 'contain' }}
        />
      )}
      <RichTextContent>{textContent}</RichTextContent>
    </Tout>
  );
}

IconTout.propTypes = {
  toutContent: PropTypes.shape({
    imageData: PropTypes.shape({
      image: PropTypes.object,
      contentType: PropTypes.oneOf(['image/jpeg', 'image/png', 'image/svg+xml']),
      iconUrl: PropTypes.string,
      imageAltText: PropTypes.string,
      iconSize: PropTypes.oneOf(Object.values(ICON_SIZE)),
    }),
    textContent: PropTypes.arrayOf(PropTypes.node),
  }).isRequired,
  toutLayout: PropTypes.oneOf(Object.values(TOUT_LAYOUT)),
};

const Tout = styled.article({
  display: 'flex',
}, ({ toutLayout }) => {
  const toutLayoutStyles = {
    LIST: {
      flexDirection: 'row',
      '& .tout-icon, & .tout-image': {
        marginRight: 20,
      },
    },
    GRID: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& .tout-icon, & .tout-image': {
        marginBottom: 10,
      },
    },
  };

  return toutLayoutStyles[toutLayout];
});

const Icon = styled.img({
  flexShrink: 0,
  height: 'auto',
}, ({ iconSize }) => {
  const iconSizeStyles = {
    SMALL: { width: 48 },
    MEDIUM: { width: 70 },
    LARGE: { width: 100 },
  };

  return iconSizeStyles[iconSize];
});

const Image = styled(GatsbyImage, {
  shouldForwardProp: (prop) => prop !== 'iconSize',
})({
  flexShrink: 0,
  height: 'auto',
}, ({ iconSize }) => {
  const iconSizeStyles = {
    SMALL: { width: 48 },
    MEDIUM: { width: 70 },
    LARGE: { width: 100 },
  };

  return iconSizeStyles[iconSize];
});

const RichTextContent = styled(WrapRichText)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});
