import PropTypes from '@root/vendor/prop-types';

export default function ChevronDownIcon({
  strokeColor = '#1d1d21',
  strokeWidth = 2,
  className,
}) {
  return (
    <svg
      className={className}
      fill={'none'}
      height={'24'}
      viewBox={'0 0 24 24'}
      width={'24'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <path
        d={'M22 7L12 17L2 7'}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}

ChevronDownIcon.propTypes = {
  className: PropTypes.string,
  strokeColor: PropTypes.string,
  strokeWidth: PropTypes.number,
};
