import environment from '@root/core/src/utils/environment';
import { isEmailAddressLink, isPhoneNumberLink } from '@root/inc.joinroot.com/src/utils/detect-link-type';

const urlMap = {
  '^https?://(www.)?joinroot.com': environment.websiteBaseUrl,
  '^https?://account.joinroot.com': environment.accountAppBaseUrl,
  '^https?://agents.joinroot.com': environment.agentsAppBaseUrl,
  '^https?://claims.joinroot.com': environment.claimsAppBaseUrl,
  '^https?://inc.joinroot.com': environment.incSiteBaseUrl,
  '^https?://quote.joinroot.com': environment.quoteAppBaseUrl,
  '^https?://renters.joinroot.com': environment.rentersAppBaseUrl,
};

const getSubdomain = (url) => {
  if (url.includes('joinroot')) {
    const match = url.replace(/https?:\/\//, '').match(/(.*).joinroot\.com/);
    return match ? match[1] : 'www';
  }
};

const environmentalizeForSubdomain = (url) => {
  if (url.match(/joinroot.com\/?/)) {
    const urlSubdomain = getSubdomain(url);
    // if routing within current subdomain, replace origin with slash
    if (urlSubdomain === environment.siteSubdomain) { return url.replace(/.*joinroot\.com\/?/, '/'); }
  }
  return url;
};

export default function environmentalizeUrl(urlToEnvironmentalize) {
  if (isPhoneNumberLink(urlToEnvironmentalize) || isEmailAddressLink(urlToEnvironmentalize) || urlToEnvironmentalize.startsWith('#')) {
    return urlToEnvironmentalize;
  }

  const updatedUrl = Object.keys(urlMap).reduce((url, urlPattern) => {
    return url.replace(new RegExp(urlPattern), urlMap[urlPattern]);
  }, environmentalizeForSubdomain(urlToEnvironmentalize));

  // this will append a trailing slash if it is missing - see the tests
  return updatedUrl.replace(/^(.*?)(\/)?(\/.*\..*|\?.*|#.*)?$$/, '$1/$3').replace(/^\/\//, '/');
}
