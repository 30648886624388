import CloseIcon from '@root/inc.joinroot.com/src/assets/icons/components/close';
import FocusTrap from 'focus-trap-react';
import ModalAnimator from '@root/inc.joinroot.com/src/components/modal/modal-animator';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useRef, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import ZIndex from '@root/core/src/utils/z-index';
import useModal from '@root/core/src/hooks/use-modal';
import usePortal from '@root/core/src/hooks/use-portal';
import { Colors, StyleSheet, styled } from '@root/core/src/utils/styles';
import { Easing } from '@root/inc.joinroot.com/src/styles/animation';
import { monochromeEasing } from '@root/inc.joinroot.com/src/styles/gradients';

export const MODAL_WIDTH = {
  Small: 420,
  Large: 920,
};

export default function ModalComponent({
  id,
  modalWidth = MODAL_WIDTH['Small'],
  children,
  isShowing,
  onClose,
  renderCloseButton = true,
}) {
  const [domLoaded, setDomLoaded] = useState(false);
  const portalRef = useRef();
  const renderPortal = usePortal(id);
  useModal(onClose, portalRef);

  useEffect(() => {
    const isBrowser = typeof window !== 'undefined';
    if (!isBrowser) { return; }
    setDomLoaded(true);

    // Locks scroll on desktop while modal is open.
    isShowing && (document.documentElement.style.overflow = 'hidden');

    return () => document.documentElement.style.overflow = '';
  }, [isShowing]);

  if (!domLoaded) { return null; }

  return renderPortal(
    <ModalAnimator>
      {isShowing && (
        <FocusTrap>
          <div
            css={styles.wrap}
            data-testid={`modal-wrapper-${id}`}
          >
            <Modal
              modalWidth={modalWidth}
              ref={portalRef}
            >
              {renderCloseButton &&
                <button
                  aria-label={'close-button'}
                  css={styles.closeButton}
                  onClick={onClose}
                >
                  <CloseIcon />
                </button>
              }
              <div css={styles.contentContainer}>
                {children}
              </div>
            </Modal>
          </div>
        </FocusTrap>
      )}
    </ModalAnimator>
  );
}

ModalComponent.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  isShowing: PropTypes.bool.isRequired,
  modalWidth: PropTypes.oneOf(Object.values(MODAL_WIDTH)),
  onClose: PropTypes.func.isRequired,
  renderCloseButton: PropTypes.bool,
};

const Modal = styled.div({
  position: 'relative',
  backgroundColor: Colors.white(),
  borderRadius: '20px 20px 0 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxHeight: 'calc(100% - 75px)',
  width: '100%',
  ...Responsive.sm({
    borderRadius: 10,
    maxHeight: '80vh',
  }),
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    width: '100%',
    height: 40,
    zIndex: 2,
    pointerEvents: 'none',
  },
  '&::before': {
    top: 0,
    backgroundImage: `linear-gradient(to bottom, ${monochromeEasing()})`,
    borderRadius: '20px 20px 0 0',
    ...Responsive.sm({ borderRadius: '10px 10px 0 0' }),
  },
  '&::after': {
    bottom: 0,
    backgroundImage: `linear-gradient(to top, ${monochromeEasing()})`,
    borderRadius: '0 0 20px 20px',
    ...Responsive.sm({ borderRadius: '0 0 10px 10px' }),
  },
}, ({ modalWidth }) => ({ maxWidth: modalWidth }));

const styles = StyleSheet.create({
  wrap: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100vw',
    height: [
      '100vh',
      '-moz-available',
      '-webkit-fill-available',
      'fill-available',
    ],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    zIndex: ZIndex.MODAL,
    ...Responsive.sm({
      height: '100vh',
      alignItems: 'center',
      padding: '0 20px',
    }),
  },
  contentContainer: {
    maxHeight: '100vh',
    width: '100%',
    padding: '30px 30px 0',
    overflowY: 'auto',
    '&::-webkit-scrollbar': { display: 'none' },
    msOverflowStyle: 'none', // Not currently supported by the parser used in Emotion
    scrollbarWidth: 'none', // Current Firefox solution and likely the future web default solution
    ...Responsive.sm({
      padding: '40px 40px 0',
    }),
    // This is a workaround to solve collapsing padding on overflow elements in Firefox.
    '&::after': {
      content: '""',
      display: 'block',
      height: 30,
      width: '100%',
      ...Responsive.sm({ height: 40 }),
    },
  },
  closeButton: {
    position: 'absolute',
    top: -64,
    zIndex: 3,
    appearance: 'none',
    border: 'none',
    padding: 0,
    margin: 4,
    width: 46,
    height: 46,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white(),
    borderRadius: 999,
    cursor: 'pointer',
    transition: `all 300ms ${Easing.easeOutQuart}`,
    '&:active': {
      backgroundColor: Colors.gray30(),
    },
    ...Responsive.sm({
      top: 0,
      right: 0,
      width: 40,
      height: 40,
      backgroundColor: Colors.toRgba(Colors.white(), 0.85),
      '&:hover': { backgroundColor: Colors.toRgba(Colors.black(), 0.04) },
      '&:focus-visible': {
        outline: 'none',
        backgroundColor: Colors.toRgba(Colors.black(), 0.1),
      },
      '&:active': {
        backgroundColor: Colors.toRgba(Colors.black(), 0.1),
      },
    }),
  },
});
