export const IMAGE_SIZE = {
  Small: 'SMALL',
  Large: 'LARGE',
  'Full bleed': 'FULL_BLEED',
};

export const SECTION_LAYOUT_X = {
  'Image left': 'IMAGE_LEFT',
  'Image right': 'IMAGE_RIGHT',
};

export const SECTION_LAYOUT_Y = {
  'Align center': 'ALIGN_CENTER',
  'Align top': 'ALIGN_TOP',
};

export const IMAGE_POSITION_X = {
  'Align center': 'ALIGN_CENTER',
  'Align left': 'ALIGN_LEFT',
  'Align right': 'ALIGN_RIGHT',
};
