import NavLinks from '@root/inc.joinroot.com/src/components/nav/nav-desktop/nav-links';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import RootIncLogo from '@root/brand/src/components/root-inc-logo';
import SmartLink from '@root/inc.joinroot.com/src/components/smart-link';
import { Colors, StyleSheet, styled } from '@root/core/src/utils/styles';

export default function NavDesktop({
  navLinks, isNavHidden, className,
}) {
  return (
    <>
      <Spacer className={className} />
      <Nav
        className={className}
        data-testid={'nav-desktop'}
        isNavHidden={isNavHidden}
      >
        <div css={styles.wrap}>
          <SmartLink
            css={styles.logoLink}
            to={'/'}
          >
            <RootIncLogo logoStyles={styles.logo} />
          </SmartLink>
          <NavLinks navLinks={navLinks} />
        </div>
      </Nav>
    </>
  );
}

NavDesktop.propTypes = {
  className: PropTypes.string,
  isNavHidden: PropTypes.bool.isRequired,
  navLinks: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  })),
};

const navHeight = 60;

const Spacer = styled.div({
  width: '100%',
  height: navHeight,
  backgroundColor: 'transparent',
});

const Nav = styled.nav({
  position: 'fixed',
  top: 0,
  zIndex: 10,
  width: '100%',
  backgroundColor: Colors.nearBlack(),
  transition: 'transform 500ms cubic-bezier(.37, 0, .28, 1)',
}, ({ isNavHidden }) => isNavHidden && {
  transform: 'translate3d(0, -100%, 0)',
});

const styles = StyleSheet.create({
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 30px',
  },
  logoLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: navHeight,
    padding: '0 10px',
  },
  logo: {
    display: 'block',
    width: 'auto',
    height: 18,
  },
});

