import PropTypes from '@root/vendor/prop-types';
import UrlConversionTrackingService from '@root/inc.joinroot.com/src/services/url-conversion-tracking-service';
import { shouldOpenNewTab } from '@root/inc.joinroot.com/src/components/smart-link/helpers';

export default function ExternalLink({
  children,
  onClick,
  to,
  ...props
}) {
  const handleClick = (event) => {
    onClick && onClick(event);
    UrlConversionTrackingService.trackConversionForUrl(to);

    if (!shouldOpenNewTab(to, event)) {
      event.preventDefault();
      window.setTimeout(() => {
        window.location = to; // eslint-disable-line root/prevent-use-of-window-location
      }, 100);
    }
  };

  return (
    <a
      data-testid={'smart-link-external'}
      href={to}
      onClick={handleClick}
      rel={'noopener noreferrer'}
      target={'_blank'}
      {...props}
    >
      {children}
    </a>
  );
}

ExternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string.isRequired,
};
