export const TOUT_VARIANT = {
  Borderless: 'BORDERLESS',
  Card: 'CARD',
};

export const TOUT_COLUMN_COUNT = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
};

export const ICON_SIZE = {
  Small: 'SMALL',
  Medium: 'MEDIUM',
  Large: 'LARGE',
};
