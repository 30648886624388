import merge from '@root/vendor/lodash/merge';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Blockquote, H1, H2, H3, H4, H5, LI, Link, OL, P1, UL } from '@root/inc.joinroot.com/src/components/text/text-elements';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => <H1>{children}</H1>,
    [BLOCKS.HEADING_2]: (node, children) => <H2>{children}</H2>,
    [BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>,
    [BLOCKS.HEADING_4]: (node, children) => <H4>{children}</H4>,
    [BLOCKS.HEADING_5]: (node, children) => <H5>{children}</H5>,
    [BLOCKS.PARAGRAPH]: (node, children) => <P1>{children}</P1>,
    [BLOCKS.UL_LIST]: (node, children) => <UL>{children}</UL>,
    [BLOCKS.OL_LIST]: (node, children) => <OL>{children}</OL>,
    [BLOCKS.LIST_ITEM]: (node, children) => <LI>{children}</LI>,
    [BLOCKS.QUOTE]: (node, children) => <Blockquote>{children}</Blockquote>,
    [INLINES.HYPERLINK]: (node, children) => <Link to={node.data.uri}>{children}</Link>,
  },
};

export default function renderRichText(content, overrideOptions) {
  if (!content || !content.json) { return; }

  return documentToReactComponents(content.json, merge({}, options, overrideOptions));
}
