export const ICON_SIZE = {
  Small: 'SMALL',
  Medium: 'MEDIUM',
  Large: 'LARGE',
};

export const DIVIDER_POSITION_X = {
  'Divider left': 'DIVIDER_LEFT',
  'Divider center': 'DIVIDER_CENTER',
  'Divider right': 'DIVIDER_RIGHT',
};

export const TOUT_LAYOUT = {
  List: 'LIST',
  Grid: 'GRID',
};
