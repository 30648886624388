import { BUTTON_VARIANT } from '@root/inc.joinroot.com/src/components/buttons/button-options';

export default function buildButton({
  button,
  trackClick = () => { },
}) {
  if (!button) { return; }

  const {
    id,
    text,
    link,
    variant,
  } = button;

  return {
    text,
    link,
    variant: BUTTON_VARIANT[variant],
    trackClick: () => trackClick(`CTA_BUTTON_${id}`, {
      text,
      link,
    }),
  };
}
