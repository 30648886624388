import PropTypes from '@root/vendor/prop-types';
import SmartLink from '@root/inc.joinroot.com/src/components/smart-link';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function NavLinks({ navLinks }) {
  return (
    <ul css={styles.ul}>
      {navLinks.map((link, i) => (
        <SmartLink
          css={styles.link}
          key={i}
          shouldTransformUrls={link.href !== 'https://inc.joinroot.com/careers/'} // ← Temporary: Careers needs to be an absolute link so that it takes the user to the failover bucket
          to={link.href}
        >
          {link.text}
        </SmartLink>
      ))}
    </ul>
  );
}

NavLinks.propTypes = {
  navLinks: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  })),
};

const styles = StyleSheet.create({
  ul: {
    display: 'flex',
    listStyle: 'none',

    '& > * + *': {
      marginLeft: '3vw',
    },
  },
  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    padding: '0 10px',
    color: Colors.white(),
    fontSize: '16px',
    textDecoration: 'none',
    transition: 'color 200ms ease-in-out',
    '&:hover': {
      color: Colors.gray40(),
    },
  },
});

