import NavMenu from '@root/inc.joinroot.com/src/components/nav/nav-mobile/nav-menu';
import NavMenuButton from '@root/inc.joinroot.com/src/components/nav/nav-mobile/nav-menu-button';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import RootIncLogo from '@root/brand/src/components/root-inc-logo';
import SmartLink from '@root/inc.joinroot.com/src/components/smart-link';
import { Colors, StyleSheet, styled } from '@root/core/src/utils/styles';

export default function NavMobile({
  navLinks, isNavHidden, className,
}) {
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);

  const toggleNavOpen = (event) => {
    event.preventDefault();
    setIsNavMenuOpen(!isNavMenuOpen);
  };

  return (
    <>
      <Spacer className={className} />
      <Nav
        className={className}
        data-testid={'nav-mobile'}
        isNavHidden={isNavHidden && !isNavMenuOpen}
      >
        <div css={styles.wrap}>
          <SmartLink
            css={styles.logoLink}
            to={'/'}
          >
            <RootIncLogo logoStyles={styles.logo} />
          </SmartLink>
          <NavMenuButton
            isNavMenuOpen={isNavMenuOpen}
            toggleNavOpen={toggleNavOpen}
          />
        </div>
        <NavMenu
          isNavMenuOpen={isNavMenuOpen}
          navLinks={navLinks}
        />
      </Nav>
    </>
  );
}

NavMobile.propTypes = {
  className: PropTypes.string,
  isNavHidden: PropTypes.bool.isRequired,
  navLinks: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  })),
};

const navHeight = 50;

const Spacer = styled.div({
  width: '100%',
  height: navHeight,
  backgroundColor: 'transparent',
});

const Nav = styled.nav({
  position: 'fixed',
  top: 0,
  zIndex: 10,
  width: '100%',
  backgroundColor: Colors.nearBlack(),
  transition: 'transform 500ms cubic-bezier(.37, 0, .28, 1)',
}, ({ isNavHidden }) => isNavHidden && {
  transform: 'translate3d(0, -100%, 0)',
});

const styles = StyleSheet.create({
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 10px',
  },
  logoLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: navHeight,
    padding: '0 10px',
  },
  logo: {
    display: 'block',
    width: 'auto',
    height: 18,
  },
});

