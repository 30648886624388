import ButtonRenderer from '@root/inc.joinroot.com/src/components/buttons/button-renderer';
import ContentfulModal from '@root/inc.joinroot.com/src/components/modal/contentful-modal';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import WrapRichText from '@root/inc.joinroot.com/src/components/text/wrap-rich-text';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { BUTTON_VARIANT } from '@root/inc.joinroot.com/src/components/buttons/button-options';
import { Colors, StyleSheet, styled } from '@root/core/src/utils/styles';
import { Easing } from '@root/inc.joinroot.com/src/styles/animation';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ICON_SIZE, TOUT_COLUMN_COUNT, TOUT_VARIANT } from '@root/inc.joinroot.com/src/components/page-sections/tout-section/tout-options';

export default function ToutStacked({
  toutContent: {
    id,
    textContent,
    imageData: {
      image,
      contentType,
      iconUrl,
      imageAltText,
      iconSize = ICON_SIZE['Large'],
    },
    button,
    modal,
  },
  toutColumnCount = TOUT_COLUMN_COUNT['3'],
  toutVariant = TOUT_VARIANT['Borderless'],
}) {
  const renderIcon = contentType === 'image/svg+xml' && iconUrl;
  const renderImage = contentType !== 'image/svg+xml' && image;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { trackClick } = useAnalytics(`TOUT_${id}`, false);

  const handleClick = () => {
    trackClick();
    setIsModalOpen(true);
  };

  const handleKeyUp = (e) => e.keyCode === 13 && handleClick();

  return (
    <>
      <Tout
        isToutClickable={!!modal}
        onClick={modal ? () => handleClick() : null}
        onKeyUp={modal ? (e) => handleKeyUp(e) : null}
        tabIndex={modal ? '0' : '-1'}
        toutColumnCount={toutColumnCount}
        toutVariant={toutVariant}
      >
        {renderIcon && (
          <Icon
            alt={imageAltText}
            className={'tout-icon'}
            data-testid={'tout-icon'}
            iconSize={iconSize}
            loading={'lazy'}
            src={iconUrl}
            toutVariant={toutVariant}
          />
        )}
        {renderImage && (
          <Image
            alt={imageAltText}
            className={'tout-image'}
            data-testid={'tout-image'}
            image={image}
          />
        )}
        <div
          className={'tout-content-container'}
          css={styles.toutContentContainer}
        >
          <WrapRichText css={styles.toutTextContainer}>{textContent}</WrapRichText>
          {button && (
            <ButtonRenderer
              onClick={button.trackClick}
              to={button.link}
              variant={button.variant}
            >
              {button.text}
            </ButtonRenderer>
          )}
        </div>
      </Tout>
      {modal && (
        <ContentfulModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          {...modal}
        />
      )}
    </>
  );
}

ToutStacked.propTypes = {
  toutColumnCount: PropTypes.oneOf(Object.values(TOUT_COLUMN_COUNT)),
  toutContent: PropTypes.shape({
    id: PropTypes.string.isRequired,
    imageData: PropTypes.shape({
      image: PropTypes.object,
      contentType: PropTypes.oneOf(['image/jpeg', 'image/png', 'image/svg+xml']),
      iconUrl: PropTypes.string,
      imageAltText: PropTypes.string,
      iconSize: PropTypes.oneOf(Object.values(ICON_SIZE)),
    }),
    textContent: PropTypes.arrayOf(PropTypes.node),
    button: PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string.isRequired,
      trackClick: PropTypes.func.isRequired,
      variant: PropTypes.oneOf(Object.values(BUTTON_VARIANT)).isRequired,
    }),
    modal: PropTypes.object,
  }).isRequired,
  toutVariant: PropTypes.oneOf(Object.values(TOUT_VARIANT)),
};

const toutMarginX = 10;

const Tout = styled.article({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: `25px ${toutMarginX}px`,
  width: `calc(100% - ${toutMarginX * 2}px)`,
}, ({
  toutVariant,
  toutColumnCount,
  isToutClickable,
}) => {
  const columnCountStyles = {
    1: { ...Responsive.sm({ width: `calc(70% - ${toutMarginX * 2}px)` }) }, // covered by base styles
    2: { ...Responsive.sm({ width: `calc(50% - ${toutMarginX * 2}px)` }) },
    3: {
      ...Responsive.sm({ width: `calc(50% - ${toutMarginX * 2}px)` }),
      ...Responsive.md({ width: `calc(33.33% - ${toutMarginX * 2}px)` }),
    },
    4: {
      ...Responsive.sm({ width: `calc(50% - ${toutMarginX * 2}px)` }),
      ...Responsive.lg({ width: `calc(25% - ${toutMarginX * 2}px)` }),
    },
  };

  const toutVariantStyles = {
    BORDERLESS: {}, // covered by base styles
    CARD: {
      backgroundColor: Colors.white(),
      borderRadius: 6,
      boxShadow: `0px 10px 30px -6px ${Colors.toRgba(Colors.black(), 0.25)}`,
      overflow: 'hidden',
      '& > .tout-content-container': { padding: '0 20px 30px' },
    },
  };

  const toutClickableStyles = {
    cursor: 'pointer',
    '& .tout-image': { transition: `opacity 300ms ${Easing.easeOutQuart}` },
    '&:hover .tout-image': { opacity: '0.85' },
    '& :is(h2, h3, h4)': {
      transition: `color 300ms ${Easing.easeOutQuart}`,
      textDecoration: 'underline',
    },
    '&:hover :is(h2, h3, h4)': { color: Colors.rootOrange() },
    '&:active :is(h2, h3, h4)': { color: Colors.toRgba(Colors.rootOrange(), 0.65) },
  };

  return {
    ...columnCountStyles[toutColumnCount],
    ...toutVariantStyles[toutVariant],
    ...isToutClickable && toutClickableStyles,
  };
});

const Icon = styled.img({
  height: 'auto',
}, ({ iconSize, toutVariant }) => {
  const iconSizeStyles = {
    SMALL: { width: 48 },
    MEDIUM: { width: 70 },
    LARGE: { width: 100 },
  };

  const toutVariantStyles = {
    BORDERLESS: {},
    CARD: { marginTop: 40 },
  };

  return {
    ...iconSizeStyles[iconSize],
    ...toutVariantStyles[toutVariant],
  };
});

const Image = styled(GatsbyImage)({
  width: '100%',
});

const styles = StyleSheet.create({
  toutContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 20px',
    textAlign: 'center',
    '& > *': { marginTop: 30 },
  },
  toutTextContainer: {
    wordBreak: 'break-word',
    '& > * + *': { marginTop: 6 },
  },
});
