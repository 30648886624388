import PropTypes from '@root/vendor/prop-types';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function RootIncLogo({ logoStyles }) {
  return (
    <svg
      aria-label={'Root Inc logo - now trading on NASDAQ'}
      aria-labelledby={'root-inc-logo-title'}
      css={[styles.svg, logoStyles]}
      height={'17'}
      role={'img'}
      version={'1.1'}
      viewBox={'0 0 88 17'}
      width={'88'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <title id={'root-inc-logo-title'}>Root Inc logo - now trading on NASDAQ</title>
      <path d={'M63.9364 16.0089H65.6892V2.96972H63.9364V16.0089Z'} />
      <path d={'M74.5785 6.13333C73.2105 6.13333 71.8424 6.92423 70.9446 7.82202V6.38984H69.2346V16.0089H70.9446V9.25419C71.65 8.52742 72.8471 7.67239 73.98 7.67239C74.8991 7.67239 75.4335 8.20678 75.4335 9.36107V16.0089H77.1436V8.82668C77.1436 6.88148 75.9252 6.13333 74.5785 6.13333Z'} />
      <path d={'M87.5391 15.389L86.9833 14.0424C86.4061 14.4058 85.6366 14.7264 84.7602 14.7264C83.1998 14.7264 81.7462 13.6576 81.7462 11.178C81.7462 8.7198 83.1998 7.62963 84.7602 7.62963C85.5297 7.62963 86.2565 7.84339 86.8337 8.20678L87.3467 6.7746C86.6413 6.43259 85.7435 6.13333 84.6961 6.13333C81.96 6.13333 79.9293 8.03578 79.9293 11.178C79.9293 14.3416 81.96 16.2441 84.6961 16.2441C86 16.2441 86.8978 15.7952 87.5391 15.389Z'} />
      <path d={'M10.425 10.2835L10.739 10.1268C12.7426 9.11722 13.8919 7.34895 13.8919 5.27344C13.8919 3.69956 13.3895 2.46428 12.3908 1.59269C11.1786 0.532987 9.22534 0 6.5937 0C4.408 0 1.65075 0.369956 0.539062 0.526717V16.3345H4.89162V10.9607H6.75072L9.83457 16.3345H14.6582L10.425 10.2835ZM6.49321 8.33968H4.89162V3.17911L5.0612 3.14776C5.42548 3.08506 5.97191 3.04116 6.41156 3.04116C8.30207 3.04116 9.34467 3.98173 9.34467 5.68729C9.34467 7.20473 8.11993 8.33968 6.49321 8.33968Z'} />
      <path d={'M27.8914 3.9693C26.5222 2.74029 24.6191 2.06309 22.5339 2.06309C20.4738 2.06309 18.5896 2.74029 17.2267 3.9693C15.7381 5.31118 14.953 7.22366 14.953 9.49983C14.953 11.776 15.7381 13.6822 17.2267 15.0241C18.5896 16.2531 20.4738 16.924 22.5339 16.924C24.6191 16.924 26.5222 16.2468 27.8914 15.0241C29.3799 13.6885 30.165 11.776 30.165 9.49983C30.1713 7.21739 29.3799 5.3049 27.8914 3.9693ZM24.5061 12.7354C24.0287 13.3875 23.363 13.7324 22.5653 13.7324C21.7739 13.7324 21.1082 13.3875 20.6308 12.7354C20.097 12.0017 19.8269 10.9107 19.8269 9.49983C19.8269 8.08271 20.097 6.99165 20.6308 6.25801C21.1082 5.59962 21.7739 5.25474 22.5653 5.25474C23.3567 5.25474 24.0287 5.59962 24.5061 6.25801C25.0399 6.99165 25.31 8.08271 25.31 9.49983C25.31 10.9107 25.0399 12.0017 24.5061 12.7354Z'} />
      <path d={'M53.1148 10.9295V6.49H56.2614V3.26699H53.1148V0.112958L48.5989 1.47991L48.6178 12.2274C48.6178 13.5631 49.026 14.7043 49.7923 15.5257C50.5773 16.3659 51.6828 16.8111 53.0017 16.8111C54.5719 16.8111 56.1861 16.5603 57.0088 16.3032V12.5096C56.5189 12.6538 55.8218 12.7918 54.9613 12.7918C53.4288 12.7918 53.1148 12.0456 53.1148 10.9295Z'} />
      <path d={'M44.7239 3.9693C43.3547 2.74029 41.4517 2.06309 39.3665 2.06309C37.3064 2.06309 35.4222 2.74029 34.0592 3.9693C32.5707 5.31118 31.7856 7.22366 31.7856 9.49983C31.7856 11.776 32.5707 13.6822 34.0592 15.0241C35.4222 16.2531 37.3064 16.924 39.3665 16.924C41.4517 16.924 43.3547 16.2468 44.7239 15.0241C46.2125 13.6885 46.9976 11.776 46.9976 9.49983C47.0039 7.21739 46.2125 5.3049 44.7239 3.9693ZM41.3386 12.7354C40.8613 13.3875 40.1955 13.7324 39.3979 13.7324C38.6065 13.7324 37.9407 13.3875 37.4634 12.7354C36.9295 12.0017 36.6595 10.9107 36.6595 9.49983C36.6595 8.08271 36.9295 6.99165 37.4634 6.25801C37.9407 5.59962 38.6065 5.25474 39.3979 5.25474C40.1892 5.25474 40.8613 5.59962 41.3386 6.25801C41.8725 6.99165 42.1426 8.08271 42.1426 9.49983C42.1426 10.9107 41.8725 12.0017 41.3386 12.7354Z'} />
    </svg>
  );
}

RootIncLogo.propTypes = {
  logoStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
};

const styles = StyleSheet.create({
  svg: {
    fill: Colors.white(),
    transition: 'fill 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955);',
  },
});
