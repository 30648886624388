import PropTypes from '@root/vendor/prop-types';
import UrlConversionTrackingService from '@root/inc.joinroot.com/src/services/url-conversion-tracking-service';
import { Link } from 'gatsby';
import { scrollToIdAnchors } from '@root/inc.joinroot.com/src/components/smart-link/helpers';

export default function InternalLink({
  children,
  onClick,
  to,
  ...props
}) {
  const handleClick = (event) => {
    onClick && onClick(event);
    UrlConversionTrackingService.trackConversionForUrl(to);
    scrollToIdAnchors(to, event);
  };

  return (
    <Link
      activeClassName={'active'}
      data-testid={'smart-link-internal'}
      onClick={handleClick}
      to={to}
      {...props}
    >
      {children}
    </Link>
  );
}

InternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string.isRequired,
};
