import Button from '@root/inc.joinroot.com/src/components/buttons/button';
import LinkButton from '@root/inc.joinroot.com/src/components/buttons/link-button';
import PropTypes from '@root/vendor/prop-types';
import { BUTTON_VARIANT } from '@root/inc.joinroot.com/src/components/buttons/button-options';

export default function ButtonRenderer(props) {
  if (props.to) { return <LinkButton {...props} />; }
  return <Button {...props} />;
}

ButtonRenderer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func,
  styleProps: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  to: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(BUTTON_VARIANT)).isRequired,
};
