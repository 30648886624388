import environment from '@root/core/src/utils/environment';

export const footerLinks = [
  {
    text: 'Company',
    href: '/company/',
  },
  environment.incSiteInvestorsLinkEnabled &&
  {
    text: 'Investors',
    href: 'https://ir.joinroot.com/',
  },
  {
    text: 'Corporate Responsibility',
    href: '/corporate-social-responsibility/',
  },
  {
    text: 'Careers',
    href: 'https://inc.joinroot.com/careers/',
  },
  {
    text: 'Press',
    href: '/press/',
  },
  {
    text: 'LinkedIn',
    href: 'https://www.linkedin.com/company/rootinsurance/',
  },
].filter(Boolean);
