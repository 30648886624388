import PropTypes from '@root/vendor/prop-types';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { body3Styles } from '@root/inc.joinroot.com/src/components/text/text-styles';

export default function FooterDisclaimer({ disclaimer }) {
  if (!disclaimer) { return <DefaultDisclaimer />; }

  return <div css={styles.container}>{disclaimer}</div>;
}

const DefaultDisclaimer = () => {
  return (
    <div css={styles.container}>
      <p>Copyright ROOT {new Date().getFullYear()}.</p>
      <p>ROOT is a registered servicemark of Root Insurance Company, Columbus, OH.</p>
    </div>
  );
};

FooterDisclaimer.propTypes = {
  disclaimer: PropTypes.node,
};

const styles = StyleSheet.create({
  container: {
    ...body3Styles,
    color: Colors.gray40(),
  },
});
