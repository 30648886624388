import PropTypes from '@root/vendor/prop-types';
import RichTextSection from '@root/inc.joinroot.com/src/components/page-sections/rich-text/rich-text-section';
import buildButton from '@root/inc.joinroot.com/src/components/page-sections/support/build-button';
import renderRichText from '@root/inc.joinroot.com/src/components/page-sections/support/render-rich-text';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { BACKGROUND_COLOR } from '@root/inc.joinroot.com/src/components/page-sections/support/background-colors';
import { BUTTON_VARIANT } from '@root/inc.joinroot.com/src/components/buttons/button-options';
import { CONTENT_LAYOUT_X } from '@root/inc.joinroot.com/src/components/page-sections/rich-text/section-options';

export default function RichTextSectionBlock({
  content: {
    id,
    anchor,
    sectionSpacing,
    backgroundColor,
    contentLayoutX,
    sectionContent: {
      textContent,
      button,
    },
  },
}) {
  const { trackClick } = useAnalytics(`RICH_TEXT_SECTION_${id}`, false);

  return (
    <div data-testid={'richTextSectionBlock'}>
      <RichTextSection
        anchor={anchor?.anchorSlug}
        backgroundColor={BACKGROUND_COLOR[backgroundColor]}
        button={buildButton({
          button,
          trackClick,
        })}
        contentLayoutX={CONTENT_LAYOUT_X[contentLayoutX]}
        sectionSpacing={sectionSpacing}
        textContent={renderRichText(textContent)}
      />
    </div>
  );
}

RichTextSectionBlock.propTypes = {
  content: PropTypes.shape({
    anchor: PropTypes.shape({
      anchorSlug: PropTypes.string,
    }),
    contentLayoutX: PropTypes.oneOf(Object.keys(CONTENT_LAYOUT_X)),
    sectionContent: PropTypes.shape({
      textContent: PropTypes.shape({
        json: PropTypes.object.isRequired,
      }),
      button: PropTypes.shape({
        id: PropTypes.string.isRequired,
        variant: PropTypes.oneOf(Object.keys(BUTTON_VARIANT)).isRequired,
        text: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
      }),
    }),
    id: PropTypes.string.isRequired,
    backgroundColor: PropTypes.oneOf(Object.keys(BACKGROUND_COLOR)),
    sectionSpacing: PropTypes.shape({
      bottom: PropTypes.number,
      top: PropTypes.number,
    }),
  }).isRequired,
};
