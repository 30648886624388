export class Easing {
  static easeOutQuart = 'cubic-bezier(0.165, 0.84, 0.44, 1)';
  static easeOutQuint = 'cubic-bezier(0.23, 1, 0.32, 1)';
  static easeOutExpo = 'cubic-bezier(0.19, 1, 0.22, 1)';
  static easeOutCirc = 'cubic-bezier(0.075, 0.82, 0.165, 1)';
  static easeInQuint = 'cubic-bezier(0.755, 0.05, 0.855, 0.06)';
}

export class Duration {
  static fast = '0.15s';
  static normal = '0.3s';
  static slow = '0.6s';
}
