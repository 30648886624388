import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import normalizeStyles from '@root/inc.joinroot.com/src/styles/normalize.css';
import { Global, css } from '@root/vendor/@emotion/core';

export default function incWrapRootElement({ element }) {
  return (
    <>
      <Global styles={css`${normalizeStyles}`} />
      {element}
    </>
  );
}

incWrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
};
