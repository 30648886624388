module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Root Inc. Website","short_name":"Root Inc.","start_url":"/","background_color":"#1d1d21","theme_color":"#1d1d21","display":"minimal-ui","icon":"./src/assets/images/inc-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f85eac6100ea79ed91e41a8947a4e9bd"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
