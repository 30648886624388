import PropTypes from '@root/vendor/prop-types';
import SmartLink from '@root/inc.joinroot.com/src/components/smart-link';
import { BUTTON_VARIANT } from '@root/inc.joinroot.com/src/components/buttons/button-options';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { Easing } from '@root/inc.joinroot.com/src/styles/animation';
import { bodyLinkStyles } from '@root/inc.joinroot.com/src/components/text/text-styles';
import { semiBold } from '@root/brand/src/assets/fonts/root-sans/emotion';

export default function LinkButton({
  styleProps,
  children,
  onClick,
  to,
  variant = BUTTON_VARIANT['Primary'],
}) {
  return (
    <SmartLink
      css={[
        styles[variant.toLowerCase()],
        styleProps,
      ]}
      data-testid={'inc-link-button'}
      onClick={onClick}
      shouldTransformUrls={!to.includes('/careers')} // ← Temporary: Careers needs to be an absolute link so that it takes the user to the failover bucket
      to={to}
    >
      {children}
    </SmartLink>
  );
}

LinkButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func,
  styleProps: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  to: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(Object.values(BUTTON_VARIANT)).isRequired,
};

const accentOrange = '#d32f2f';

const svgBorderGradient = (color1, color2) => {
  const removeHashSymbol = (hexColor) => hexColor.slice(1);

  return `url('data:image/svg+xml;utf8,
    <svg xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="gradient" x1="0" x2="100%" y1="0" y2="0" gradientUnits="userSpaceOnUse">
          <stop stop-color="%23${removeHashSymbol(color1)}" offset="0"/>
          <stop stop-color="%23${removeHashSymbol(color2)}" offset="100%"/>
        </linearGradient>
      </defs>
      <rect x="1" y="1" width="100%" height="100%" style="height:calc(100% - 2px);width:calc(100% - 2px)" rx="5" ry="5" stroke-width="2" fill="transparent" stroke="url(%23gradient)"/>
    </svg>
  ')`;
};

const baseStyles = {
  ...semiBold(),
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 60,
  minWidth: 220,
  padding: '6px 26px',
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: 6,
  cursor: 'pointer',
  fontSize: 18,
  letterSpacing: '-0.15px',
  lineHeight: '130%',
  textDecoration: 'none',
  transition: `all 200ms ${Easing.easeOutQuart}`,
  userSelect: 'none',
  outline: 'none',
  '&:focus': {
    boxShadow: `0 0 0 6px ${Colors.toRgba(Colors.rootOrange(), 0.3)}`,
  },
};

const basePseudoStyles = {
  content: '""',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: 6,
};

const styles = StyleSheet.create({
  primary: {
    ...baseStyles,
    color: Colors.white(),
    zIndex: 0,
    '&:before': {
      ...basePseudoStyles,
      zIndex: -2,
      backgroundColor: Colors.rootOrange(),
      backgroundImage: `linear-gradient(55deg, ${Colors.rootOrange()} 35%, ${accentOrange} 75%)`,
    },
    '&:after': {
      ...basePseudoStyles,
      zIndex: -1,
      backgroundColor: Colors.rootOrange(),
      boxShadow: `0 14px 10px -10px ${Colors.toRgba(Colors.nearBlack(), 0.3)}`,
      transition: `opacity 600ms ${Easing.easeOutQuart}`,
      opacity: 0,
    },
    '&:not(:active):not(:focus):hover:after': {
      opacity: 1,
    },
  },

  secondary: {
    ...baseStyles,
    backgroundImage: svgBorderGradient(Colors.rootOrange(), accentOrange),
    color: Colors.nearBlack(),
    zIndex: 0,

    '&:after': {
      ...basePseudoStyles,
      zIndex: -1,
      border: `2px solid ${Colors.nearBlack()}`,
      transition: `opacity 300ms ${Easing.easeOutQuart}`,
      opacity: 0,
    },

    '&:hover:after': {
      opacity: 1,
    },
  },

  tertiary: {
    ...bodyLinkStyles,
  },
});

