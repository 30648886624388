export const IMAGE_WIDTH = {
  Small: 'SMALL',
  Large: 'LARGE',
  'Extra large': 'EXTRA_LARGE',
  'Full viewport': 'FULL_VIEWPORT',
};

export const IMAGE_HEIGHT = {
  Auto: 'AUTO',
  Small: 'SMALL',
  Medium: 'MEDIUM',
  Large: 'LARGE',
  'Full viewport': 'FULL_VIEWPORT',
};
