import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { BACKGROUND_COLOR } from '@root/inc.joinroot.com/src/components/page-sections/support/background-colors';
import { Colors, styled } from '@root/core/src/utils/styles';

export default function ExpandableSection({
  anchor,
  children,
  backgroundColor = BACKGROUND_COLOR['White'],
  sectionSpacing = {},
}) {
  return (
    <Section
      backgroundColor={backgroundColor}
      id={anchor}
      sectionSpacing={sectionSpacing}
    >
      <Wrap>{children}</Wrap>
    </Section>
  );
}

ExpandableSection.propTypes = {
  anchor: PropTypes.string,
  backgroundColor: PropTypes.oneOf(Object.values(BACKGROUND_COLOR)),
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
};

const Section = styled.section({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  padding: '100px 20px',
}, ({ backgroundColor, sectionSpacing }) => {
  const sectionSpacingStyles = {
    ...Responsive.lessThanMd({
      paddingTop: sectionSpacing.top / 1.25,
      paddingBottom: sectionSpacing.bottom / 1.25,
    }),
    paddingTop: sectionSpacing.top,
    paddingBottom: sectionSpacing.bottom,
  };

  const backgroundColorStyles = {
    WHITE: { backgroundColor: Colors.white() },
    OFF_WHITE: { backgroundColor: Colors.nearWhite() },
  };

  return {
    ...sectionSpacingStyles,
    ...backgroundColorStyles[backgroundColor],
  };
});

const Wrap = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 'min(800px, 100%)',
});
