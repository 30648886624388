import ArtDirectedGatsbyImage from '@root/inc.joinroot.com/src/components/art-directed-gatsby-image';
import MainContent from '@root/inc.joinroot.com/src/components/main-content';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { BACKGROUND_COLOR } from '@root/inc.joinroot.com/src/components/page-sections/support/background-colors';
import { CONTENT_COLOR, CONTENT_LAYOUT_X, IMAGE_HEIGHT } from '@root/inc.joinroot.com/src/components/page-sections/feature-image-with-content/section-options';
import { Colors, styled } from '@root/core/src/utils/styles';

export default function FeatureImageWithContentSection({
  anchor,
  backgroundColor = BACKGROUND_COLOR['White'],
  imageHeight = IMAGE_HEIGHT['Full viewport'],
  image: {
    desktopImg,
    imageAltText,
    mobileImg,
  },
  contentLayoutX = CONTENT_LAYOUT_X['Align center'],
  contentColor = CONTENT_COLOR['Light'],
  mainContent,
  sectionSpacing = {},
}) {
  return (
    <Section
      backgroundColor={backgroundColor}
      id={anchor}
      imageHeight={imageHeight}
      sectionSpacing={sectionSpacing}
    >
      <ContentWrap
        contentColor={contentColor}
        contentLayoutX={contentLayoutX}
      >
        <MainContent {...mainContent} />
      </ContentWrap>
      <Image
        alt={imageAltText}
        desktopImg={desktopImg}
        mobileImg={mobileImg}
        responsiveBreakpoint={Responsive.QUERIES.lessThanMd}
        style={{ position: 'absolute' }}
      />
    </Section>
  );
}

FeatureImageWithContentSection.propTypes = {
  anchor: PropTypes.string,
  backgroundColor: PropTypes.oneOf(Object.values(BACKGROUND_COLOR)),
  contentColor: PropTypes.oneOf(Object.values(CONTENT_COLOR)),
  contentLayoutX: PropTypes.oneOf(Object.values(CONTENT_LAYOUT_X)),
  image: PropTypes.shape({
    desktopImg: PropTypes.object.isRequired,
    mobileImg: PropTypes.object,
    imageAltText: PropTypes.string.isRequired,
  }).isRequired,
  imageHeight: PropTypes.oneOf(Object.values(IMAGE_HEIGHT)),
  mainContent: PropTypes.object.isRequired,
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
};

const Section = styled.section({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}, ({
  sectionSpacing,
  imageHeight,
  backgroundColor,
}) => {
  const sectionSpacingStyles = {
    ...Responsive.lessThanMd({
      paddingTop: sectionSpacing.top / 1.25,
      paddingBottom: sectionSpacing.bottom / 1.25,
    }),
    paddingTop: sectionSpacing.top,
    paddingBottom: sectionSpacing.bottom,
  };

  const backgroundColorStyles = {
    WHITE: { backgroundColor: Colors.white() },
    OFF_WHITE: { backgroundColor: Colors.nearWhite() },
  };

  const imageHeightStyles = {
    MEDIUM: {
      minHeight: 260,
      ...Responsive.md({ minHeight: 430 }),
    },
    LARGE: {
      minHeight: 380,
      ...Responsive.md({ minHeight: 600 }),
    },
    FULL_VIEWPORT: { minHeight: 'calc(100vh - 50px)' },
  };

  return {
    ...sectionSpacingStyles,
    ...backgroundColorStyles[backgroundColor],
    ...imageHeightStyles[imageHeight],
  };
});

const ContentWrap = styled.div({
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  width: 'min(1200px, 100%)',
  padding: '30px 20px',
  alignItems: 'center',
  textAlign: 'center',
  '& > div': {
    maxWidth: 800,
  },
  '& > * + *': {
    marginTop: 30,
  },
}, ({ contentLayoutX, contentColor }) => {
  const contentLayoutXStyles = {
    ALIGN_LEFT: {
      ...Responsive.md({
        alignItems: 'flex-start',
        textAlign: 'left',
        '& > div': {
          maxWidth: 600,
        },
      }),
    },
    ALIGN_CENTER: {}, // accounted for by default styles
  };

  const contentColorStyles = {
    LIGHT: {
      '& > div *': { color: Colors.white() },
      '& > div p': { color: Colors.toRgba(Colors.white(), 0.6) },
      '& > a': { color: Colors.white() },
    },
    DARK: {
      '& > div p': { color: Colors.toRgba(Colors.black(), 0.6) },
    },
  };

  return {
    ...contentLayoutXStyles[contentLayoutX],
    ...contentColorStyles[contentColor],
  };
});

const Image = styled(ArtDirectedGatsbyImage)({
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});
