import ArtDirectedGatsbyImage from '@root/inc.joinroot.com/src/components/art-directed-gatsby-image';
import MainContent from '@root/inc.joinroot.com/src/components/main-content';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { BACKGROUND_COLOR } from '@root/inc.joinroot.com/src/components/page-sections/support/background-colors';
import { Colors, styled } from '@root/core/src/utils/styles';
import { IMAGE_POSITION_X, SECTION_LAYOUT_X } from '@root/inc.joinroot.com/src/components/page-sections/side-by-side-with-image/section-options';

export default function SideBySideWithImageSectionFullBleed({
  anchor,
  mainContent,
  backgroundColor = BACKGROUND_COLOR['White'],
  sectionLayoutX = SECTION_LAYOUT_X['Image left'],
  image: {
    desktopImg,
    imageAltText,
    mobileImg,
    imagePositionX = IMAGE_POSITION_X['Align center'],
  },
  sectionSpacing = {},
}) {
  return (
    <Section
      backgroundColor={backgroundColor}
      id={anchor}
    >
      <Wrap sectionLayoutX={sectionLayoutX}>
        <Image
          alt={imageAltText}
          desktopImg={desktopImg}
          imgStyle={setImageStyle(imagePositionX)}
          mobileImg={mobileImg}
          responsiveBreakpoint={Responsive.QUERIES.lessThanLg}
          sectionLayoutX={sectionLayoutX}
        />
        <ContentContainer
          sectionLayoutX={sectionLayoutX}
          sectionSpacing={sectionSpacing}
        >
          <MainContent {...mainContent} />
        </ContentContainer>
      </Wrap>
    </Section>
  );
}

SideBySideWithImageSectionFullBleed.propTypes = {
  anchor: PropTypes.string,
  backgroundColor: PropTypes.oneOf(Object.values(BACKGROUND_COLOR)),
  image: PropTypes.shape({
    imageAltText: PropTypes.string.isRequired,
    desktopImg: PropTypes.object.isRequired,
    mobileImg: PropTypes.object,
    imagePositionX: PropTypes.oneOf(Object.values(IMAGE_POSITION_X)),
  }).isRequired,
  mainContent: PropTypes.object.isRequired,
  sectionLayoutX: PropTypes.oneOf(Object.values(SECTION_LAYOUT_X)),
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
};

const Section = styled.section({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  overflow: 'hidden',
}, ({ backgroundColor }) => {
  const backgroundColorStyles = {
    WHITE: { backgroundColor: Colors.white() },
    OFF_WHITE: { backgroundColor: Colors.nearWhite() },
  };

  return backgroundColorStyles[backgroundColor];
});

const Wrap = styled.div({
  ...Responsive.lessThanLg({
    flexDirection: 'column',
  }),
  display: 'flex',
  width: 'min(1200px, 100%)',
  margin: '0 8%',
}, ({ sectionLayoutX }) => sectionLayoutX === 'IMAGE_RIGHT'
  ? { flexDirection: 'row-reverse' }
  : {});

const Image = styled(ArtDirectedGatsbyImage)({
  ...Responsive.lessThanLg({
    margin: '0 calc(50% - 50vw)',
  }),
  flex: 1,
}, ({ sectionLayoutX }) => sectionLayoutX === 'IMAGE_RIGHT'
  ? { marginRight: 'calc(50% - 50vw)' }
  : { marginLeft: 'calc(50% - 50vw)' });

const ContentContainer = styled.div({
  ...Responsive.lessThanLg({
    width: '100%',
    padding: '30px 0',
    alignItems: 'center',
    textAlign: 'center',
  }),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '42%',
  padding: '30px 0',
  textAlign: 'left',
  '& > * + *': { marginTop: 14 },
  '& > a': {
    maxWidth: 240,
    marginTop: 35,
  },
}, ({ sectionLayoutX, sectionSpacing }) => {
  const sectionLayoutXStyles = {
    IMAGE_LEFT: { paddingLeft: '12%' },
    IMAGE_RIGHT: { paddingRight: '12%' },
  };

  const sectionSpacingStyles = {
    ...Responsive.lessThanLg({
      paddingBottom: sectionSpacing.bottom / 1.25,
    }),
    paddingTop: sectionSpacing.top,
    paddingBottom: sectionSpacing.bottom,
  };

  return {
    ...sectionLayoutXStyles[sectionLayoutX],
    ...sectionSpacingStyles,
  };
});

const setImageStyle = (imagePosition) => {
  const imagePositionXStyles = {
    ALIGN_CENTER: { objectPosition: 'center' },
    ALIGN_LEFT: { objectPosition: 'left' },
    ALIGN_RIGHT: { objectPosition: 'right' },
  };

  return imagePositionXStyles[imagePosition];
};
